import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieKey } from "@src/app/shared/enum/cookie-key.enum";
import { environment } from "@src/environments/environment";
import { get, set } from "lodash";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";


@Injectable()
export class Workflow2AuthInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var auth = this.cookieService.get(CookieKey.JsonWebToken);
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${auth}`,
        SameSite: 'None'
      }
    });
    return next.handle(req);
  }
}

@Injectable()
export class SideMenuHackInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (req.url.includes('/api/checkFuncRole')) {
    //   // this is before the request is sent
    //   console.log('/api/checkFuncRole request', req.body);
    // }
    // KL: YA, so we got the funcRole request here, but can't change it without re-calculating the header-hash; which is too much work ATM...
    //     just implement a hacky 'workaround' in activate-guard.service.ts for now...

    return next.handle(req).pipe(
      // this is after the response is received... async & non-deterministic
      tap(val => {
        // if (req.url.includes('/api/checkFuncRole')) {
        //   var body = get(val, 'body');
        //   if (!environment.production && !!body) {
        //     console.log('/api/checkFuncRole response', body);

        //     set(body, 'body.hasAuthorized', true);
        //   }
        // }

        // if (req.url.includes('/api/queryFuncRoleList')) {
        //   if (!environment.production) {
        //     // console.log(val);
        //   }
        // }
      })
    );
  }
}