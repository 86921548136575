<div class="b-modal" [ngClass]="size">
  <div class="b-modal-container">
    <div (click)="close()" class="b-modal-close" *ngIf="crossBtnIsShow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#222"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 6l12.774 12.774M6 18.774L18.774 6"
        />
      </svg>
    </div>
    <div class="re-modal-body fix" [ngClass]="modalBodyClass">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="b-modal-background"></div>
