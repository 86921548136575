import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RandomService {
  constructor() {}

  /**
   * 隨機英數字串
   * @param length 字串長度
   */
  public englishAndNumbers(length: number): string {
    let text = '';
    const possible = '0123456789abcdefghijklmnopqrstuvwxyz';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * 隨機數字
   * @param length 數字長度
   */
  public numbers(length: number): number {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
  }

  /**
   * 隨機英文字串
   * @param length 字串長度
   */
  public english(length: number): string {
    let text = '';
    const possible = 'abcdefghijklmnopqrstuvwxyz';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
