import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { environment as env } from '@src/environments/environment';
import { EnvMode } from '@src/app/shared/enum/env-mode.enum';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ActivateGuard } from './shared/service/activate-guard.service';

const routes: Routes = [
  // { path: 'cathayOperation/workflows', canActivateChild: [ActivateGuard],
  //   loadChildren: () => import('./private-page/cathay-operation-page/workflow2/workflow2.module').then((m) => m.Workflow2Module).catch((e) => { console.log(e) }) },
  // { path: 'cathayOperation', canActivateChild: [ActivateGuard],
  //   loadChildren: () => import('./private-page/cathay-operation-page/cathay-operation-page.module').then((m) => m.CathayOperationPageModule).catch((e) => { console.log(e) }) },
  // KL: 20221216 - this works, but styling is broken since these pages depends on styles defined by private-page ~~ for for the moment, must be routed through private-page

  // { path: '', loadChildren: () => import('./public-page/modules/home/home.module').then((m) => m.HomeModule).catch((e) => console.log(e)) },
  { path: '', loadChildren: () => import('src/app/public-page/public-page.module').then(m => m.PublicPageModule), },
  { path: '', loadChildren: () => import('src/app/private-page/private-page.module').then(m => m.PrivatePageModule), },
];
// declare let gtag: Function;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private gtmService: GoogleTagManagerService, private router: Router) {
    // if (env.environment === EnvMode.UAT) {
    //   gtag('config', env.googleGA);
    // }
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    })
  }
}
