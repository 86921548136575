<app-modal [id]="modalId" [size]="'b-inner-sm-box'">
  <h3>{{title}}</h3>
  <div class="modal-body text-center modal-scroll">
    <div class="offline modal-content w-e-text-container ">
      您可透過以下方式檢查網際網路連線狀態：
      <ul>
        <li>檢查網路線、數據機和路由器</li>
        <li>重新透過 Wi-Fi 或行動數據網路連線至網際網路</li>
        <li>點擊下方「重新載入」按鈕重新整理頁面</li>
      </ul>
    </div>
  </div>
  <div class="f-btn-flex-area">
    <button class="f-btn f-btn-short" (click)="submitReload()">{{ "common.reload" | translate }}</button>
  </div>
</app-modal>
