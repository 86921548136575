import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CustomModalComponent } from './custom-modal/custom-modal.component';

@NgModule({
  declarations: [ConfirmModalComponent, CustomModalComponent],
  imports: [CommonModule],
  exports: [ConfirmModalComponent, CustomModalComponent],
})
export class PublicModalModule {}
