import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Role } from '../enum/role.enum';
import { ResStatus } from '../enum/res-status.enum';
import { StorageService } from './storage.service';
import { StorageKey } from '../enum/storage-key.enum';
import { CookieKey } from '../enum/cookie-key.enum';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  constructor(private storage: StorageService, 
    private cookieService: CookieService) {}

  /**
   * 是否為內部使用者
   */
  innerUser = new BehaviorSubject('');
  /**
   * 使用者身份 CUSTOMER/USER/ADMIN/SUPERADMIN/MANAGER
   */
  roleId = '';

  /**
   * 使用者ID (UUID) (userInfo)
   */
  userId = '';

  /**
   * 使用者姓名 (userInfo)
   */
  userName = new BehaviorSubject('');

  /**
   * 使用者 Email
   */
  account = new BehaviorSubject('');

  /**
   * 使用者職稱
   */
  userPosition = new BehaviorSubject('');

  /**
   * 使用者電話
   */
  userPhone = new BehaviorSubject('');

  /**
   * 產業列別 id
   */
  industryId = new BehaviorSubject('');

  /**
   * 產業別名稱
   */
  industryName = new BehaviorSubject('');

  /**
   * 公司名稱
   */
  businessName = new BehaviorSubject('');

  /**
   * 公司名稱
   */
  isBinding = new BehaviorSubject('N');

  /**
   * 是否需要變更密碼
   */
  needChangePwd = new BehaviorSubject(false);

  /**
   * 公司資本額
   */
  stockAmount = new BehaviorSubject('');

  /**
   * 單位ID (UUID) (userInfo)
   */
  groupId = '';

  /**
   * 企業ID (UUID) (userInfo)
   */
  businessId = '';

  /**
   *  業務代碼 (userInfo)
   */
  serviceCode = '';

  /**
   * 營管用戶ID
   */
  managerId = new BehaviorSubject('');

  /**
   * RoleId為MANAGER時，是否為Administrator角色
   */
  administrator = '';

  /**
   * RoleId為MANAGER時，是否為營管角色
   */
  operator = '';

  /**
   * RoleId為MANAGER時，是否為客服角色
   */
  service = '';

  /**
   * RoleId為MANAGER時，是否為技術角色
   */
  technology = '';
  /**
   * RoleId為MANAGER時，是否為業務角色
   */
  salesPerson = '';
  techServiceCodeList = [''];
  /**
   * 企業戶統一編號
   */
  taxId = new BehaviorSubject('');
  /**
   * 企業戶地址
   */
  businessAddress = new BehaviorSubject('');
  /**
   * 負責人姓名
   */
  superAdminName = new BehaviorSubject('');
  /**
   * 聯絡人姓名
   */
  adminName = new BehaviorSubject('');
  /**
   * 聯絡人Email
   */
  adminEmail = new BehaviorSubject('');
  /**
   * 聯絡人電話
   */
  adminPhone = new BehaviorSubject('');
  /**
   * 聯絡人職稱
   */
  adminPosition = new BehaviorSubject('');

  isInnerUser(): boolean {
    return this.innerUser.value === ResStatus.true;
  }

  isCustomer(): boolean {
    return this.roleId === Role.CUSTOMER;
  }

  isUser(): boolean {
    return this.roleId === Role.USER;
  }

  isAdmin(): boolean {
    return this.roleId === Role.ADMIN;
  }

  isSuperAdmin(): boolean {
    return this.roleId === Role.SUPER_ADMIN;
  }

  isManager(): boolean {
    return this.roleId === Role.MANAGER;
  }

  isAdministrator(): boolean {
    return this.administrator === ResStatus.true;
  }

  isOperator(): boolean {
    return this.operator === ResStatus.true;
  }

  isService(): boolean {
    return this.service === ResStatus.true;
  }

  isTechnology(): boolean {
    return this.technology === ResStatus.true;
  }

  isSalesPerson(): boolean {
    return this.salesPerson === ResStatus.true;
  }

  isOperationManager(): boolean {
    return this.cookieService.get(CookieKey.isOperationManager) === ResStatus.true;
  }

  /**
   * 清除角色資訊
   */
  clearRoleData(): void {
    this.innerUser.next('');
    this.roleId = '';
    this.userId = '';
    this.groupId = '';
    this.businessId = '';
    this.administrator = '';
    this.operator = '';
    this.service = '';
    this.technology = '';
    this.account.next('');
    this.userName.next('');
    this.industryId.next('');
    this.businessName.next('');
    this.isBinding.next('N');
    this.needChangePwd.next(false);
    this.managerId.next('');
    this.salesPerson = '';
    this.taxId.next('');
    this.businessAddress.next('');
    this.superAdminName.next('');
    this.adminName.next('');
    this.adminEmail.next('');
    this.adminPhone.next('');
    this.stockAmount.next('');
    this.adminPosition.next('');
    this.industryName.next('');
    this.userPosition.next('');
    this.userPhone.next('');
  }
}
