export enum UploadFileType {
  DOCUMENT = 'document',
  FILE = 'file',
  ATTACHMENT = 'attachment',
  PROPOSALFILE = 'proposalFile',
  FORMFILE = 'formFile',
  PROPOSALFILENAME = 'proposalFileName',
  PROPOSALFILEDESC = 'proposalFileDesc',
  LOGOFILENAME = 'logoFileName',
  LOGOFILE = 'logoFile',
  GOODSFILENAME1 = 'goodsFileName1',
  GOODSFILE1 = 'goodsFile1',
  GOODSFILENAME2 = 'goodsFileName2',
  GOODSFILE2 = 'goodsFile2',
  GOODSFILENAME3 = 'goodsFileName3',
  GOODSFILE3 = 'goodsFile3',
  BRANCHFILENAME = 'branchFileName',
  BRANCHFILE = 'branchFile',
  EXPOSUREFILENAME = 'exposureFileName',
  EXPOSUREFILE = 'exposureFile',
  MULTIFILE = 'multiFile',
  ANNFILES = 'annFiles', // 公告附件檔案
  BACKGROUNDIMGFILE = 'backgroundImgFile' // 特別企劃圖片
}
