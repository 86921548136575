import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {YouTubePlayer} from '@angular/youtube-player';

@Component({
  standalone: true,
  imports: [YouTubePlayer],
  template: '<youtube-player [videoId]="videoId" [playerVars]="playerVars" (stateChange)="playerStateChanged($event)" />',
  selector: 'youtube-player-video',
})
export class YoutubePlayerComponent  {
  @Input() videoId: string = '';

  playerVars = {
    autoplay:1,
    playsinline:1,
    mute:1,
    showinfo:0,
    modestbranding:0,
    loop:1
  }
  playerStateChanged(event: YT.OnStateChangeEvent): void {
    if (event.data === YT.PlayerState.ENDED) {
      event.target.playVideo();
    }
  }
}