import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';
dayjs.locale('zh-tw');

export function now() {
  return dayjs().valueOf();
}

export function formatUnix(value: number) {
  return dayjs(value).format('YYYY/MM/DD HH:mm');
}

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: any): string {
    return value ? dayjs(value).format('YYYY/MM/DD') : '';
  }
}

@Pipe({
  name: 'dateTimeFormat',
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: any): string {
    return value ? dayjs(value).format('YYYY/MM/DD HH:mm') : '';
  }
}
@Pipe({
  name: 'dateTimeSecondDashFormat',
})
export class DateTimeSecondDashFormatPipe implements PipeTransform {
  transform(value: any): string {
    return value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '';
  }
}

@Pipe({
  name: 'dateTimeSecondBasicFormat',
})
export class DateTimeSecondBasicFormatPipe implements PipeTransform {
  transform(value: any): string {
    return value ? dayjs(value).format('YYYYMMDDHHmmss') : '';
  }
}

@Pipe({
  name: 'dateTimeSecondFormat',
})
export class DateTimeSecondFormatPipe implements PipeTransform {
  transform(value: any): string {
    return value ? dayjs(parseInt(value) * 1000).format('YYYY/MM/DD HH:mm') : '';
  }
}

@Pipe({
  name: 'twelveClockFormat',
})
export class TwelveClockFormatPipe implements PipeTransform {
  transform(value: any): string {
    const hourMin = parseInt(value.slice(8, 10), 10);
    const twelveClock = hourMin - 12 > 0 ? '下午' : '上午';
    const dateTime = dayjs(value).format('YYYY/MM/DD hh:mm');
    const fullDateTime = dateTime.slice(0, 10) + twelveClock + dateTime.slice(11, 16);
    return fullDateTime;
  }
}

@Pipe({
  name: 'monthFormat',
})
export class MonthFormatPipe implements PipeTransform {
  transform(value: any): string {
    return dayjs(value).format('YYYYMM');
  }
}

@Pipe({
  name: 'monthFormat',
})
export class MonthSlashFormatPipe implements PipeTransform {
  transform(value: any): string {
    return dayjs(value).format('YYYY/MM');
  }
}

@Pipe({
  name: 'monthDateYearTimeFormat',
})
export class MonthDateYearTimeFormatPipe implements PipeTransform {
  transform(value: any): string {
    return dayjs(value).format('MM/DD/YYYY HH:mm') ;
  }
}