import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface FormFinished {
  title: string;
  subTitle: string;
  btns: FormFinishedBtn[];
}
interface FormFinishedBtn {
  text: string;
  link: string;
}

interface FormRegister {
  registerUserId: string;
  userId: string;
  vertifyType: string;
  acCode: string;
  pcode: string;
  pcodeConfirm: string;
  uName: string;
  position: string;
  userPhone: string;
  // 統編搜尋公司名稱
  businessName: string;
  companyName: string;
  // 統編ID
  taxId: string;
  // 自行輸入
  areaCode: string;
  phone: string;
  extension: string;
  // 單位
  groupId: string;
  // 產業別
  industryId: string;
  website: string;
  address: string;
  agree: string;
}

interface FormBindCheck {
  companyName: string;
  superAdmin: string;
  superAdminStatus: string;
  orgName: string;
  orgNameStatus: string;
  admin: string;
  adminStatus: string;
  isShowView: boolean;
  isAllStatus: boolean;
}

interface Group {
  groupId: string;
  groupName: string;
}

interface FormUploadFile {
  file: File | null;
  fileSize: number;
  documentPath: string;
  fileUpload: string;
  fileSizeInRange: boolean;
  fileRequiredIsShow: boolean;
  uploadError: boolean;
  fileFormatIsWrong: boolean;
}

interface FormBusiness {
  businessId: string;
  businessName: string;
  taxId: string;
  industryId: string;
  website: string;
  address: string;
  phone: string;
  stockAmount: string;
}

interface FormBusiness {
  businessId: string;
  businessName: string;
  taxId: string;
  industryId: string;
  website: string;
  address: string;
  phone: string;
  stockAmount: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormStepsService {
  // 判斷內外部註冊
  $isInnerRegister = new BehaviorSubject<boolean>(false);

  // 判斷內部驗證狀態
  $isInnerValidateStatus = new BehaviorSubject<string>(''); // 0無效統編/ 1升級企業戶/ 2綁定企業戶

  // 內部-組織企業單位
  $innerBusiness = new BehaviorSubject<FormBusiness>({
    businessId: '',
    businessName: '',
    taxId: '',
    industryId: '',
    website: '',
    address: '',
    phone: '',
    stockAmount: '',
  });

  // 註冊者ID
  $registerId = new BehaviorSubject<string>('');

  // 判斷外部統編是否有註冊
  $isRegister = new BehaviorSubject<string>(''); // 0沒註冊 1有註冊

  // 註冊者Info
  $registerUserId = new BehaviorSubject<string>('');
  $registerName = new BehaviorSubject<string>('');
  $registerAccount = new BehaviorSubject<string>('');
  $registerPosition = new BehaviorSubject<string>('');
  $registerPhone = new BehaviorSubject<string>('');

  // 表單步驟
  $totalSteps = new BehaviorSubject<number>(4);
  $currentStep = new BehaviorSubject<number>(1);

  // 信箱認證信內容
  $formFinishedConten = new BehaviorSubject<FormFinished>({
    title: '',
    subTitle: '',
    btns: [{ text: '', link: '' }],
  });

  // 外部企業綁定審核資料
  $bindCheckContent = new BehaviorSubject<FormBindCheck>({
    companyName: '',
    superAdmin: '',
    superAdminStatus: '',
    orgName: '',
    orgNameStatus: '',
    admin: '',
    adminStatus: '',
    isShowView: false,
    isAllStatus: false,
  });

  // 註冊資訊
  $registerInfo = new BehaviorSubject<FormRegister>({
    registerUserId: '',
    userId: '',
    vertifyType: '',
    acCode: '',
    pcode: '',
    pcodeConfirm: '',
    uName: '',
    position: '',
    userPhone: '',
    // 統編搜尋公司名稱
    businessName: '',
    companyName: '',
    // 統編ID
    taxId: '',
    // 自行輸入
    areaCode: '',
    phone: '',
    extension: '',
    // 單位
    groupId: '',
    // 產業別
    industryId: '',
    website: '',
    address: '',
    agree: '',
  });

  // 企業單位資訊
  $groupList = new BehaviorSubject<Group[]>([
    {
      groupId: '',
      groupName: '',
    },
  ]);

  // 外部-綁定上一步(是否3回2換網址)
  $isBindingPrevious = new BehaviorSubject<boolean>(false);

  // 上傳檔案上傳檔案
  $uploadFile = new BehaviorSubject<FormUploadFile>({
    file: null,
    fileSize: 0,
    documentPath: '',
    fileUpload: '',
    fileSizeInRange: true,
    fileRequiredIsShow: false,
    uploadError: true,
    fileFormatIsWrong: false,
  });

  $isSendEmailFail = new BehaviorSubject<boolean>(false);

  constructor() {}

  isInnerRegister(): Observable<boolean> {
    return this.$isInnerRegister.asObservable();
  }
  changeInnerRegister(state: boolean): void {
    this.$isInnerRegister.next(state);
  }
  changeRegisterId(id: string): void {
    this.$registerId.next(id);
  }

  // 表單步驟
  changeCurrentStep(step: number): void {
    this.$currentStep.next(step);
  }
  changeTotalSteps(allSteps: number): void {
    this.$totalSteps.next(allSteps);
  }
  // 表單完成內容
  changeFormFinishedContent(content: FormFinished): void {
    this.$formFinishedConten.next(content);
  }

  // 檢核統扁
  isRegister(isRegister: string): void {
    this.$isRegister.next(isRegister);
  }

  // 註冊資訊
  changeRegisterInfo(info: FormRegister): void {
    this.$registerInfo.next(info);
  }

  // 企業單位資訊
  changeGroupList(business: Group[]): void {
    this.$groupList.next(business);
  }

  // 外部企業綁定審核資料
  changeBindCheckContent(bindCheckContent: FormBindCheck) {
    this.$bindCheckContent.next(bindCheckContent);
  }

  // 外部-綁定上一步
  changeBindingPrevious(isBindingPrevious: boolean): void {
    this.$isBindingPrevious.next(isBindingPrevious);
  }

  // 外部-上傳檔案
  changeUploadFile(uploadFile: FormUploadFile): void {
    this.$uploadFile.next(uploadFile);
  }
  // 外部-上傳檔案(清空)
  changeClearUploadFile(): void {
    const uploadObj = {
      file: null,
      fileSize: 0,
      documentPath: '',
      fileUpload: '',
      fileSizeInRange: true,
      fileRequiredIsShow: false,
      uploadError: true,
      fileFormatIsWrong: false,
    };
    this.$uploadFile.next(uploadObj);
  }

  // 外部-發送郵件狀態
  changeIsSendEmailFail(isSendEmailFail: boolean): void {
    this.$isSendEmailFail.next(isSendEmailFail);
  }

  // 內部 - 驗證狀態
  isInnerValidateStatus(validateStatus: string): void {
    this.$isInnerValidateStatus.next(validateStatus);
  }
  // 內部 -組織企業單位
  changeInnerBusiness(info: FormBusiness): void {
    this.$innerBusiness.next(info);
  }
}