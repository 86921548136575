import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import apiLoadingIgnore from '@shared/enum/api-loading-ignore.enum';

@Injectable({
  providedIn: 'root',
})
export class PageLoadingService {
  /**
   * UI display
   */
  loadingSub = new BehaviorSubject<boolean>(true);
  /**
   * 包含進行中的加載請求
   */
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  loadingOverride = new BehaviorSubject(false);

  constructor() {}

  /**
   * Sets the loadingSub property value based on the following:
   * - If loading is true, add the provided url to the loadingMap with a true value, set loadingSub value to true
   * - If loading is false, remove the loadingMap entry and only when the map is empty will we set loadingSub to false
   * This pattern ensures if there are multiple requests awaiting completion, we don't set loading to false before
   * other requests have completed. At the moment, this function is only called from the @link{HttpRequestInterceptor}
   * @param loading boolean
   * @param url string
   */
  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error(
        'The request URL must be provided to the PageLoadingService.setLoading function'
      );
    }
    // ignore api
    const apiUrl = url.split('/api')[1];
    for (const values of Object.values(apiLoadingIgnore)) {
      const ignoreUrl = values.path.split('/api')[1];
      if (apiUrl === ignoreUrl) {
        return;
      }
    }
    if (loading === true) {
      this.loadingMap.set(url, loading);
      this.loadingSub.next(true);
    } else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }
    if (this.loadingMap.size === 0) {
      this.loadingSub.next(false);
    }
  }

  showLoading(): void {
    this.loadingSub.next(true);
  }

  hideLoading(): void {
    this.loadingSub.next(false);
  }

  setOverride() {
    this.loadingOverride.next(true);
  }

  clearOverride() {
    this.loadingOverride.next(false);
  }
}
