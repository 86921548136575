import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { tap } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { PageLoadingService } from '@shared/layout/components/page-loading/page-loading.service';
import { environment } from '@src/environments/environment';
import { startsWith } from 'lodash';

@Injectable()
export class PageLoadingInterceptor implements HttpInterceptor {
  private ignoreList = [
    '/api/checkIsInnerAccount',
    '/api/getCaptcha',
    // '/apiv2/tenants',
    '/apiv2/ticketTemplate',
    '/apiv2/tenants/itList',
    '/apiv2/tenants/poList',
    '/apiv2/tenants/locateCurrentMember',
    '/apiv2/tickets/logs',
    '/apiv2/wfOperations/download',
    '/apiv2/wfOperations/packages',
    '/api/checkCaptchaCode',
    '/api/validateTaxGetOrganization',
    '/api/queryIndustryTagList',
    '/api/queryRecommandPackageList',
    '/apiv2/status',
    '/api/checkFuncRole',
    '/api/queryFuncRoleList',
    // '/api/queryLoginUserInfo',
    '/api/queryServiceCodeList',
    '/api/queryGroupUserList',
    '/api/queryOuterOrgList',
  ]

  private ignoreList2 = [
    ['GET', '/apiv2/wfOperations/apigee/products', 'tenantPrefix'],
    ['GET', '/apiv2/wfOperations/apigee/proxies', 'tenantPrefix'],
    ['GET', '/apiv2/wfOperations/packages', 'tenantPrefix'],
  ]

  totalRequests = 0;
  completedRequests = 0;

  constructor(private pageLoading: PageLoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      // console.log(request.params);
      const pathname = startsWith(request.url, '/') ? request.url : (new URL(request.url)).pathname;
      if (this.ignoreList.includes(pathname)) {
        return next.handle(request);
      }
      for (let i = 0; i < this.ignoreList.length; i++) {
        if (pathname.startsWith(this.ignoreList[i])) {
          return next.handle(request);
        }
      }

      // take care of complex cases
      for (let i = 0; i < this.ignoreList2.length; i++) {
        if (request.method == this.ignoreList2[i][0] &&
          pathname == this.ignoreList2[i][1] &&
          !!request.params.get(this.ignoreList2[i][2])) {
          return next.handle(request);
        }
      }

      // if (!environment.production) {
      //   console.log('loading intercept', pathname);
      // }
    }
    catch {
      if (request.url.startsWith('./assets')) {
        // nothing to see here...
        return next.handle(request);
      }

      if (!environment.production) {
        console.log('loading intercept error', request);
      }
      return next.handle(request);
    }

    this.totalRequests++;
    this.pageLoading.showLoading();

    return next.handle(request).pipe(
      finalize(() => {

        this.completedRequests++;

        if (this.completedRequests === this.totalRequests) {
          this.completedRequests = 0;
          this.totalRequests = 0;

          setTimeout(() => {
            // this.pageLoading.hideLoading();
            this.pageLoading.loadingOverride.subscribe(val => {
              if (!val) {
                this.pageLoading.hideLoading();
              }
            })
          }, 500);
        }
      })
    );
  }
}
