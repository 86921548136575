export default {
  API000000: { funcId: 'API000000', path: '/oauth/token' },
  API000001: { funcId: 'API000001', path: '/api/validateTaxGetOrganization' },
  API000002: { funcId: 'API000002', path: '/api/queryFuncRoleList' },
  API000003: { funcId: 'API000003', path: '/api/checkFuncRole' },
  API000004: { funcId: 'API000004', path: '/api/getPublicKey' },
  API000005: { funcId: 'API000005', path: '/api/queryLoginUserInfo' },
  API000006: { funcId: 'API000006', path: '/api/expiredToken' },
  // B01
  API010101: { funcId: 'API010101', path: '/api/queryAnnouncement' },
  // B02
  API020101: { funcId: 'API020101', path: '/api/queryUserInfo' },
  API020102: { funcId: 'API020102', path: '/api/updateUserInfo' },
  API020103: { funcId: 'API020103', path: '/api/updateUserPrivacy' },
  API020104: { funcId: 'API020104', path: '/api/upgradeOrganization' },
  API020105: { funcId: 'API020105', path: '/api/bindOrganization' },
  API020106: { funcId: 'API020106', path: '/api/cancelBindOrganization' },
  API020107: { funcId: 'API020107', path: '/api/deleteAccount' },
  API020108: { funcId: 'API020108', path: '/api/outsideUpgradeOrganization' },
  API020109: { funcId: 'API020109', path: '/api/outsideAddOrgGidInfo' },
  API020110: { funcId: 'API020110', path: '/api/outsideBindOrg' },
  API020201: { funcId: 'API020201', path: '/api/updateOrganization' },
  API020202: { funcId: 'API020202', path: '/api/queryOrganization' },
  API020203: { funcId: 'API020203', path: '/api/applyDeleteOrganization' },
  API020301: { funcId: 'API020301', path: '/api/queryFollowing' },
  API020302: { funcId: 'API020302', path: '/api/deleteFollowing' },
  // B03
  API030101: { funcId: 'API030101', path: '/api/queryOrgReqPackageListByGroupId' },
  API030102: { funcId: 'API030102', path: '/api/deleteOrgReqPackage' },
  API030103: { funcId: 'API030103', path: '/api/previewProductDoc' },
  API030104: { funcId: 'API030104', path: '/api/updateUserReqAuthorityByReqId' },
  API030105: { funcId: 'API030105', path: '/api/queryOrgReqPackageDetail' },
  API030106: { funcId: 'API030106', path: '/api/queryGroupUserList' },
  API030107: { funcId: 'API030107', path: '/api/getApigeeApiKey' },
  API030108: { funcId: 'API030108', path: '/api/queryOrgReqApiKeyList' },
  API030109: { funcId: 'API030109', path: '/api/deleteSalesPromotions' },
  API030110: { funcId: 'API030110', path: '/api/previewServiceProductDoc' },
  API030111: { funcId: 'API030111', path: '/api/queryBUPackageList' },
  API030112: { funcId: 'API030112', path: '/api/queryBUApiProductList' },
  API030113: { funcId: 'API030113', path: '/api/addBUPackage' },
  API030114: { funcId: 'API030114', path: '/api/selectedBUCooperationCasesList' },
  API030115: { funcId: 'API030115', path: '/api/selectedBUCooperationCasesDetail' },
  API030116: { funcId: 'API030116', path: '/api/submitCooperationCases' },
  API030117: { funcId: 'API030117', path: '/api/deleteCustomizedReqPackage' },
  API030118: { funcId: 'API030118', path: '/api/updateReqPackageNewStatus' },
  API030119: { funcId: 'API030119', path: '/api/updateReqPackageApplyUser' },
  API030120: { funcId: 'API030120', path: '/api/queryOuterOrgList' },
  API030201: { funcId: 'API030201', path: '/api/queryOrgReqApiProductList' },
  API030301: { funcId: 'API030301', path: '/api/queryApiKeyInfoList' },
  API030302: { funcId: 'API030302', path: '/api/addApiKey' },
  API030303: { funcId: 'API030303', path: '/api/updateApiKey' },
  API030304: { funcId: 'API030304', path: '/api/addTemporaryKey' },
  API030305: { funcId: 'API030305', path: '/api/reminderToChangeKey' },
  // B04
  API040101: { funcId: 'API040101', path: '/api/queryOrgUserInfoList' },
  API040102: { funcId: 'API040102', path: '/api/updateOrgGidInfo' },
  API040103: { funcId: 'API040103', path: '/api/assignAdminUser' },
  API040104: { funcId: 'API040104', path: '/api/updateUserIsBusiness' },
  API040106: { funcId: 'API040106', path: '/api/bindingOrgReview' },
  API040107: { funcId: 'API040107', path: '/api/updateNewGroup' },
  API040108: { funcId: 'API040108', path: '/api/assignNewAdminUser' },
  API040109: { funcId: 'API040109', path: '/api/cancelBindingReview' },
  API040110: { funcId: 'API040110', path: '/api/assignUser' },
  API040111: { funcId: 'API040111', path: '/api/queryInnerGroupList' },
  API040112: { funcId: 'API040112', path: '/api/cancelAssignAdmin' },
  API040113: { funcId: 'API040113', path: '/api/cancelAssignUser' },
  API040114: { funcId: 'API040114', path: '/api/bindingAdminReview' },
  API040115: { funcId: 'API040115', path: '/api/updateApplyOrgId' },

  // B05
  API050101: { funcId: 'API050101', path: '/api/statisticsUserIndustry' },
  API050201: { funcId: 'API050201', path: '/api/statisticsHotApi' },
  API050301: { funcId: 'API050301', path: '/api/queryBusinessNotifyList' },
  API050302: { funcId: 'API050302', path: '/api/updateBusinessNotify' },
  API050401: { funcId: 'API050401', path: '/api/queryOrgReqPackageListByServiceCode' },
  API050402: { funcId: 'API050402', path: '/api/updateOrgReqPackage' },
  API050403: { funcId: 'API050403', path: '/api/addUserReqPackage' },
  API050404: { funcId: 'API050404', path: '/api/extendContract' },
  API050405: { funcId: 'API050405', path: '/api/notifyTechnologyAssess' },
  API050406: { funcId: 'API050406', path: '/api/querySpecialOrgReqPackage' },
  API050407: { funcId: 'API050407', path: '/api/updateSalesPromotions' },
  API050408: { funcId: 'API050408', path: '/api/queryBpmOrgReqPackageList' },
  API050409: { funcId: 'API050409', path: '/api/auditBpmOrgReqPackage' },
  API050410: { funcId: 'API050410', path: '/api/queryBpmOrgReqPackageDetail' },
  API050501: { funcId: 'API050501', path: '/api/queryAllFile' },
  API050502: { funcId: 'API050502', path: '/api/uploadFile' },
  API050503: { funcId: 'API050503', path: '/api/downloadFile' },
  API050601: { funcId: 'API050601', path: '/api/queryBUTrackReport' },
  API050602: { funcId: 'API050602', path: '/api/queryApiOrgReqApigeeKeyList' },
  API050603: { funcId: 'API050603', path: '/api/queryApiOrgReqApiProductList' },
  API050604: { funcId: 'API050604', path: '/api/queryApiOrgReqPackageDetail' },
  API050701: { funcId: 'API050701', path: '/api/querySPReqList' },
  API050702: { funcId: 'API050702', path: '/api/querySPReqDetail' },
  API050703: { funcId: 'API050703', path: '/api/editSPReqContacted' },
  API050704: { funcId: 'API050704', path: '/api/editSPReqRemark' },
  // B06
  API060101: { funcId: 'API060101', path: '/api/addCooperationNote' },
  API060102: { funcId: 'API060102', path: '/api/queryBusinessNote' },
  API060201: { funcId: 'API060201', path: '/api/addCooperationBoard' },
  API060202: { funcId: 'API060202', path: '/api/addCooperationBoardMessage' },
  API060203: { funcId: 'API060203', path: '/api/queryAllCooperation' },
  API060204: { funcId: 'API060204', path: '/api/queryCooperationDetail' },
  API060205: { funcId: 'API060205', path: '/api/followCooperation' },
  API060206: { funcId: 'API060206', path: '/api/forwardCooperation' },
  API060207: { funcId: 'API060207', path: '/api/querySalespersonList' },
  API060208: { funcId: 'API060208', path: '/api/queryInviteAccountOverview' },
  API060209: { funcId: 'API060209', path: '/api/updateInviteUserInfo' },
  API060210: { funcId: 'API060210', path: '/api/queryInviteUpgradeAuditInfo' },
  API060211: { funcId: 'API060211', path: '/api/reviewInviteBusinessDoc' },
  API060212: { funcId: 'API060212', path: '/api/deleteInviteBusinessAccount' },
  API060213: { funcId: 'API060213', path: '/api/applyServiceHistory' },
  // B07
  API070101: { funcId: 'API070101', path: '/api/setPackageTag' },
  API070102: { funcId: 'API070102', path: '/api/queryOrgBusinessGroup' },
  API070103: { funcId: 'API070103', path: '/api/queryTechnologyGroup' },
  API070104: { funcId: 'API070104', path: '/api/updateTechnologyGroup' },
  API070105: { funcId: 'API070105', path: '/api/queryPackageList' },
  API070106: { funcId: 'API070106', path: '/api/queryScenesList' },
  API070108: { funcId: 'API070108', path: '/api/queryTechnologyManagerList' },
  API070109: { funcId: 'API070109', path: '/api/queryAllOrgReqPackageList' },
  API070110: { funcId: 'API070110', path: '/api/selectedCooperationCasesList' },
  API070111: { funcId: 'API070111', path: '/api/selectedCooperationCasesDetail' },
  API070112: { funcId: 'API070112', path: '/api/auditCooperationCases' },
  API070113: { funcId: 'API070113', path: '/api/editCooperationCasesSort' },
  API070114: { funcId: 'API070114', path: '/api/queryOPBpmOrgReqPackageList' },
  API070115: { funcId: 'API070115', path: '/api/auditOPBpmOrgReqPackage' },
  API070116: { funcId: 'API070116', path: '/api/queryOPBpmOrgReqPackageDetail' },
  API070117: { funcId: 'API070117', path: '/api/queryOPApiOrgReqApigeeKeyList' },
  API070118: { funcId: 'API070118', path: '/api/queryOPApiOrgReqApiProductList' },
  API070119: { funcId: 'API070119', path: '/api/queryOPApiOrgReqPackageDetail' },
  API070201: { funcId: 'API070201', path: '/api/assignSuperAdminUser' },
  API070202: { funcId: 'API070202', path: '/api/deleteOrgBid' },
  API070203: { funcId: 'API070203', path: '/api/auditUpgradeBusiness' },
  API070204: { funcId: 'API070204', path: '/api/updateManagerRoleList' },
  API070205: { funcId: 'API070205', path: '/api/queryManagerRoleList' },
  API070206: { funcId: 'API070206', path: '/api/queryAccountOverview' },
  API070207: { funcId: 'API070207', path: '/api/queryUpgradeAuditInfo' },
  API070208: { funcId: 'API070208', path: '/api/reviewBusinessDoc' },
  API070209: { funcId: 'API070209', path: '/api/superAdminSubmission' },
  API070210: { funcId: 'API070210', path: '/api/queryADGroupList' },
  API070211: { funcId: 'API070211', path: '/api/updateADOrgGidInfo' },
  API070212: { funcId: 'API070212', path: '/api/queryOuterUserInfoList' },
  API070213C: { funcId: 'API070213C', path: '/api/queryTaxId' }, // 查詢公司登記統編資料
  API070214C: { funcId: 'API070214C', path: '/api/addTaxId' }, // 新增公司登記統編資料
  API070301: { funcId: 'API070301', path: '/api/querySystemList' },
  API070401: { funcId: 'API070401', path: '/api/querySalespersonNotify' },
  API070401C: { funcId: 'API070401C', path: '/api/queryAnnouncementByType' },
  API070402: { funcId: 'API070402', path: '/api/addAnnouncement' },
  API070402C: { funcId: 'API070402C', path: '/api/addAnnounce' },
  API070403: { funcId: 'API070403', path: '/api/addSalespersonNotify' },
  API070403C: { funcId: 'API070403C', path: '/api/editAnnounce' },
  API070404: { funcId: 'API070404', path: '/api/queryContactUs' },
  API070404C: { funcId: 'API070404C', path: '/api/queryAnnouncementById' },
  API070405: { funcId: 'API070405', path: '/api/deleteAnnouncement' },
  API070406: { funcId: 'API070406', path: '/api/contactUsIsRead' },
  API070407: { funcId: 'API070407', path: '/api/queryFollowReport' },
  API070409: { funcId: 'API070409', path: '/api/queryTrackReport' },
  API070302: { funcId: 'API070302', path: '/api/updateSystem' },
  API070501: { funcId: 'API070501', path: '/api/addPackage' },
  API070504: { funcId: 'API070504', path: '/api/technologyAssessReqIdList' },
  API070505: { funcId: 'API070505', path: '/api/apiExceptionNotifyList' },
  API070506: { funcId: 'API070506', path: '/api/queryApiProductList' },
  API070507: { funcId: 'API070507', path: '/api/queryPackageInfo' },
  API070508: { funcId: 'API070508', path: '/api/queryServiceCodeList' },
  API070509: { funcId: 'API070509', path: '/api/updateOrgReqPackageByTech' },
  API070510: { funcId: 'API070510', path: '/api/updateApiExceptionNotify' },
  API070511: { funcId: 'API070511', path: '/api/customizedReqPackageSplit' },
  API070512: { funcId: 'API070512', path: '/api/queryAllPackageInfo' },
  API070513: { funcId: 'API070513', path: '/api/queryPackageApigeeInfo' },
  API070514: { funcId: 'API070514', path: '/api/queryReqPackageApigeeInfo' },
  API070515: { funcId: 'API070515', path: '/api/queryITBpmOrgReqPackageList' },
  API070516: { funcId: 'API070516', path: '/api/auditITBpmOrgReqPackage' },
  API070517: { funcId: 'API070517', path: '/api/queryCannedInformation' },
  API070518: { funcId: 'API070518', path: '/api/queryITBpmOrgReqPackageDetail' },
  API070519: { funcId: 'API070519', path: '/api/queryTApiOrgReqApigeeKeyList' },
  API070520: { funcId: 'API070520', path: '/api/queryTApiOrgReqApiProductList' },
  API070521: { funcId: 'API070521', path: '/api/queryTApiOrgReqPackageDetail' },
  // B09
  API090101: { funcId: 'API090101', path: '/api/queryDashboardData' },
  API090201: { funcId: 'API090201', path: '/api/queryApigeeTraffic' },
  API090202: { funcId: 'API090202', path: '/api/queryApigeeEnvironmentList' },
  API090203: { funcId: 'API090203', path: '/api/editApigeeEnvironmentList' },
  API070601: { funcId: 'API070601', path: '/api/queryOPSpecialPlanningList' },
  API070602: { funcId: 'API070602', path: '/api/editSpecialPlanningSort' },
  API070603: { funcId: 'API070603', path: '/api/querySpecialPlanningDetail' },
  API070604: { funcId: 'API070604', path: '/api/editSpecialPlanningDetail' },
  API070605: { funcId: 'API070605', path: '/api/querySPReqOpList' },
  API070606: { funcId: 'API070606', path: '/api/querySPReqOpDetail' },
  API070607: { funcId: 'API070607', path: '/api/editOPSPReqContacted' },
  // 前台

  // A01
  APIA10101: { funcId: 'APIA10101', path: '/api/getCaptcha' },
  APIA10102: { funcId: 'APIA10102', path: '/api/sendRegisterVertify' },
  APIA10103: { funcId: 'APIA10103', path: '/api/register' },
  APIA10104: { funcId: 'APIA10104', path: '/api/getOuterInviteRegisterBindingInfo' },
  APIA10105: { funcId: 'APIA10105', path: '/api/outerInviteRegisterBinding' },
  APIA10106: { funcId: 'APIA10106', path: '/api/outerInviteBinding' },
  APIA10107: { funcId: 'APIA10107', path: '/api/contactUsRegister' },
  APIA10108: { funcId: 'APIA10108', path: '/api/downloadRegister' },
  APIA10109: { funcId: 'APIA10109', path: '/api/checkAccountState' },
  APIA10110: { funcId: 'APIA10110', path: '/api/checkCaptchaCode' },
  APIA10111: { funcId: 'APIA10111', path: '/api/sendPrivacyInfo' },
  APIA10112: { funcId: 'APIA10112', path: '/api/resetPassword' },
  APIA10113: { funcId: 'APIA10113', path: '/api/checkIsInnerAccount' },
  APIA10114: { funcId: 'APIA10114', path: '/api/downloadPackageDoc' },
  APIA10115: { funcId: 'APIA10115', path: '/api/registerUpgradeOrganization' },
  APIA10116: { funcId: 'APIA10116', path: '/api/registerBindOrganization' },
  APIA10117: { funcId: 'APIA10117', path: '/api/getInnerCode' },
  APIA10118: { funcId: 'APIA10118', path: '/api/innerInviteRegisterBinding' },
  APIA10119: { funcId: 'APIA10119', path: '/api/innerInviteBinding' },
  APIA10120: { funcId: 'APIA10120', path: '/api/queryIndustryList' },
  APIA10121: { funcId: 'APIA10121', path: '/api/addOrgGidInfo' },
  APIA10122: { funcId: 'APIA10122', path: '/api/outsideRegister' },
  APIA10123: { funcId: 'APIA10123', path: '/api/validateTaxGetOrg' },
  APIA10124: { funcId: 'APIA10124', path: '/api/outsideRegisterUpgradeOrganization' },
  APIA10125: { funcId: 'APIA10125', path: '/api/outsideBindOrganization' },
  APIA10202: { funcId: 'APIA10202', path: '/sso/innerAuth' },
  APIA10203: { funcId: 'APIA10203', path: '/sso/innerRegisterAuth' },
  APIA10204: { funcId: 'APIA10204', path: '/sso/inviteRegisterAuth' },

  // A02

  APIA20101: { funcId: 'APIA20101', path: '/api/queryRecommandPackageList' },
  APIA20102: { funcId: 'APIA20102', path: '/api/queryIndustryTagList' },

  // A03
  APIA30101: { funcId: 'APIA30101', path: '/api/queryPackageOverviewList' },
  APIA30102: { funcId: 'APIA30102', path: '/api/queryPackageDetail' },
  APIA30103: { funcId: 'APIA30103', path: '/api/followPackage' },
  APIA30104: { funcId: 'APIA30104', path: '/api/applyPackage' },
  APIA30105: { funcId: 'APIA30105', path: '/api/queryPackageNewestList' },
  APIA30106: { funcId: 'APIA30106', path: '/api/queryServiceTagList' },
  APIA30107: { funcId: 'APIA30107', path: '/api/queryFrontScenesList' },
  APIA30108: { funcId: 'APIA30108', path: '/api/queryCooperationCasesList' },
  APIA30109: { funcId: 'APIA30109', path: '/api/queryCooperationCasesDetail' },
  APIA30201: { funcId: 'APIA30201', path: '/api/applySalesPromotions' },
  APIA30202: { funcId: 'APIA30202', path: '/api/downloadContractTemplate' },
  APIA30301: { funcId: 'APIA30301', path: '/api/querySpecialPlanningList' },
  APIA30302: { funcId: 'APIA30302', path: '/api/applySpecialPlanningA' },
  APIA30304: { funcId: 'APIA30304', path: '/api/querySpreadsheet' },
  APIA30305: { funcId: 'APIA30305', path: '/api/checkTaxId' },

  // A05
  APIA50101: { funcId: 'APIA50101', path: '/api/topList' },

  // A07
  APIA70101: { funcId: 'APIA70101', path: '/api/sendConnectionMsg' },
  APIA70101C: { funcId: 'APIA70101C', path: '/api/updateEmailSubscriptionStatus ' },

  // A08
  APIA80101C: { funcId: 'APIA80101C', path: '/api/getNews' },
  APIA80102C: { funcId: 'APIA80102C', path: '/api/getAnnouncement' },
  APIA80103C: { funcId: 'APIA80103C', path: '/api/getAnnouncementById' },
};
