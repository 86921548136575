import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit {
  @Input() modalId = '';
  @Input() title: string = '';
  @Input() content = '';

  constructor(public modalService: ModalService) { }

  ngOnInit(): void {
  }

  submitReload(): void {
    this.reloadPage();
    this.modalService.close(this.modalId);
  }

  reloadPage() {
    window.location.reload();
  }

}
