import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  isOnline = new BehaviorSubject<boolean>(true);

  constructor() {
    this.isOnline.next(navigator.onLine); 

    window.addEventListener('online', () => {
      this.isOnline.next(true);
    });

    window.addEventListener('offline', () => {
      this.isOnline.next(false);
    });
  }

  getStatusObservable(): Observable<boolean> {
    return this.isOnline.asObservable();
  }

}
