export default {
  // B01
  API000001: { funcId: 'API000001', path: '/api/validateTaxGetOrganization' },
  // A01
  APIA10110: { funcId: 'APIA10110', path: '/api/checkCaptchaCode' },
  APIA10101: { funcId: 'APIA10101', path: '/api/getCaptcha' },
  APIA10113: { funcId: 'APIA10113', path: '/api/checkIsInnerAccount' },
  // A02
  APIA20102: { funcId: 'APIA20102', path: '/api/queryIndustryTagList' },
  APIA20101: { funcId: 'APIA20101', path: '/api/queryRecommandPackageList' },

  // // A03
  // API030108: { funcId: ' API030108', path: '/api/queryOrgReqApiKeyList' },

  API070210: { funcId: 'API070210', path: '/api/queryADGroupList' },
};
