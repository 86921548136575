import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id = '';
  @Input() size = '';
  @Input() modalBodyClass = '';
  @Output() closeEvent = new EventEmitter();
  public crossBtnIsShow = true;
  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id exists
    if (!this.id) {
      return;
    }
    // move element to bottom of page (just before </body>)
    document.body.appendChild(this.element);

    // 點擊背景關掉modal註解
    // close modal on background click
    // this.element.addEventListener('click', (el: any) => {
    //   if (el.target.className === 'modal') {
    //     this.close();
    //   }
    // });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open
  open(crossBtnIsShow?: boolean): void {
    this.crossBtnIsShow = crossBtnIsShow ?? true;
    this.element.style.display = 'block';
    document.body.classList.add('b-modal-open');
  }
  // close
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('b-modal-open');
    this.closeEvent.emit(); // 傳給父層關閉事件
  }

  modalSize(): string {
    return this.size;
  }
}
