<!--
  固定樣式 template:
  標題/內文/按鈕
-->
<div class="b-modal">
  <div class="b-modal-container">
    <div (click)="cross()" class="b-modal-close" *ngIf="crossBtnIsShow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#222"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 6l12.774 12.774M6 18.774L18.774 6"
        />
      </svg>
    </div>
    <div class="b-modal-body">
      <h3>{{ title }}</h3>
      <p class="one-line-p" [innerHTML]="content"></p>

      <div class="b-model-btn-area" [class]="btnClass">
        <button
          class="f-btn f-btn-med f-btn-outline"
          *ngIf="buttons == buttonsSets.OKandCancel"
          (click)="cancel()"
        >
          {{ cancelText }}
        </button>
        <button class="f-btn f-btn-med f-btn-right" (click)="ok()">{{ okText }}</button>
      </div>
      <div class="prompt-area"></div>
    </div>
  </div>
</div>
<div class="b-modal-background"></div>
