import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalComponent } from './modal.component';
import { CommonModalComponent } from './common-modal.component';
import { MaintenanceAnnouncementPopupComponent } from './maintenance-announcement-popup/maintenance-announcement-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { OfflineComponent } from './offline/offline.component';

@NgModule({
  declarations: [ModalComponent,CommonModalComponent, MaintenanceAnnouncementPopupComponent, OfflineComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ModalComponent,CommonModalComponent, MaintenanceAnnouncementPopupComponent, OfflineComponent],
})
export class ModalModule {}
