import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { environment as env } from '@src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { delay, distinctUntilChanged } from 'rxjs/operators';

import { PageLoadingService } from '@shared/layout/components/page-loading/page-loading.service';
import { StorageService } from '@shared/service/storage.service';
import { CheckHelperService } from '@src/app/shared/service/check-helper.service';
import { EnvMode } from '@src/app/shared/enum/env-mode.enum';
import { UserInfoService } from '@shared/service/user-info.service';
import { HttpService } from '@shared/service/http.service';

import { TranslateService } from '@ngx-translate/core';

import localeEn from '@angular/common/locales/en';
import localeZh from '@angular/common/locales/zh';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PageTitleService } from './shared/service/page-title.service';
import { FormStepsService } from './public-page/modules/user-management/register/service/form-steps.service';
import { ModalService } from '@src/app/shared/_modal/modal.service';
import { StorageKey } from './shared/enum/storage-key.enum';
import { ConnectionService } from './shared/service/connection.service';
import { OfflineComponent } from './shared/_modal/offline/offline.component';
import { CookieService } from 'ngx-cookie-service';
import { CookieKey } from './shared/enum/cookie-key.enum';
import { InitialLoadingService } from './public-page/modules/home/initial-loading/initial-loading.service';

declare let gtag: Function;
declare global {
  interface Window {
    cubcsadoReInit: typeof Function;
    dataLayer: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('offlineModal', { static: false }) offlineModal!: OfflineComponent;
  
  // MB
  mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  isMobile = new BehaviorSubject<boolean>(this.mobileDevice ? true : false);

  // 互動視窗
  time = 1000 * 60 * 3;
  userActivity: ReturnType<typeof setTimeout> = setTimeout(() => '', this.time);

  // loading
  loading = false;

  isLogin = false;

  isOnline: boolean = true;
  offlineAnnId = 'offline-popup';
  offlineTitle = '您處於離線狀態';

  constructor(
    private router: Router,
    public pageLoading: PageLoadingService,
    private checkHelper: CheckHelperService,
    public storage: StorageService,
    private userInfo: UserInfoService,
    private httpService: HttpService,
    public formStepsSvc: FormStepsService,
    private translate: TranslateService,
    private gtmService: GoogleTagManagerService,
    private pageService: PageTitleService,
    private connectionService: ConnectionService,
    private modalService: ModalService,
    private cookieService: CookieService,
    public initialLoading: InitialLoadingService,
  ) {
    this.homeInitLoading();
    this.translate.addLangs(['en', 'zh']);
    this.translate.setDefaultLang('zh');
  }

  ngOnInit(): void {
    // console.log('---BPN upper version 220929.2---');  // do not use console-log to check your application version. use the deployed image-tag. you can find this by clicking the copyright info in the page-footer
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeZh, 'zh');
    this.pageService.setTitle();

    this.httpService.subLoginState().subscribe(value => {
      this.isLogin = value;
    });
    this.router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          if (current instanceof NavigationEnd) {
            return previous.url === current.url;
          }
          return true;
        })
      )
      .subscribe((x: any) => {
        const url = window.location.href;
        const bookmarkIndex = url.indexOf('#');
        let newUrl = url.toString();  // deprecate?

        if (bookmarkIndex !== -1) {
          newUrl = url.substring(0, bookmarkIndex);
        }

        newUrl = newUrl.concat('#');
        newUrl = newUrl.concat(new Date().getTime().toString());

        // Celebrus
        this.reinitialse();

        this.gtmService.pushTag({ event: 'page', pageName: url });
        // GA in UAT ENV
        // if (env.environment === EnvMode.UAT) {
        //   // gtag('config', env.googleGA, { page_path: url });
        //   this.gtmService.pushTag({event: 'page', pageName: url});
        // }
        // GTM in PROD ENV
        if (env.environment === EnvMode.PROD) {
          const gtmTag = {
            event: 'page',
            pageName: url,
          };
          if (this.isLogin && this.userInfo.userId) {
            const loginInfo = {
              userId: this.userInfo.businessId ?? '',
              companyName: this.userInfo.businessName.value ?? '',
              user: this.userInfo.userId ?? '',
            };
            Object.assign(gtmTag, loginInfo);
          }
          window.dataLayer.push(gtmTag);
        }

        // 黃色互動視窗
        if (x.url === '/') {
          this.initialLoading.isHomeInitial = true;
          this.initCheckApi();
        } else {
          this.initialLoading.isHomeInitial = false;
        }
        if (x.url === '/contactUs') {
          this.contactStopCheckApi();
        }

        if (x.url !== '/register' && this.formStepsSvc.$isBindingPrevious.value) {
          this.formStepsSvc.changeBindingPrevious(false);
        }

        if (
          x.url !== '/registerSuccess' &&
          this.formStepsSvc.$bindCheckContent.value.isShowView
        ) {
          const bindCheckList = {
            companyName: '',
            superAdmin: '',
            superAdminStatus: '',
            orgName: '',
            orgNameStatus: '',
            admin: '',
            adminStatus: '',
            isShowView: false,
            isAllStatus: false,
          };

          this.formStepsSvc.changeBindCheckContent(bindCheckList);
        }

        const registerBinding = x.url.substr(0, x.url.indexOf('?'));
        if (x.url !== '/registerSuccess' && registerBinding !== '/registerBinding' && x.url !== '/register') {
          const registerInfoList = {
            registerUserId: '',
            userId: '',
            vertifyType: '',
            acCode: '',
            pcode: '',
            pcodeConfirm: '',
            uName: '',
            position: '',
            userPhone: '',
            businessName: '',
            companyName: '',
            taxId: '',
            areaCode: '',
            phone: '',
            extension: '',
            groupId: '',
            industryId: '',
            website: '',
            address: '',
            agree: '',
          };
          this.formStepsSvc.changeRegisterInfo(registerInfoList);
          this.formStepsSvc.changeClearUploadFile();
          this.formStepsSvc.isRegister('');
        }
        
        if(!x.url.includes('/cathayOperation/workflows/tickets')) {
          this.storage.remove(StorageKey.ticketListFilterData);
        }

        if(!x.url.includes('/notificationCenter/announcement')) {
          this.storage.remove(StorageKey.annListStoreData);
        }

        if(!x.url.includes('/cathayOperation/messagesManage/announcement')) {
          this.storage.remove(StorageKey.opAnnListStoreData);
          this.storage.remove(StorageKey.frontAnnStoreData)
        }

        const initHideLoadingPage = ['/selfServices','/developerCenter', '/faq','/aboutUs','/info','/login', '/register','/unsubscribe','/innerLogin','/forgetPassword','/resetPassword','/resetPasswordFinished','/outerInviteRegisterConfirm','/innerInviteRegisterConfirm','/outerInviteBindingConfirm','/innerInviteBindingConfirm','/outerRegisterVerifyEmail','/registerBinding','/registerSuccess','downloadRegisterInner','/downloadRegisterConfirm','/registerFail','/invitedSuccess','/invitedFail','/invitedBindingSuccess', '/sceneCases','/contactUs']
        if (initHideLoadingPage.some(page => x.url.startsWith(page))) {
          this.pageLoading.hideLoading();
        }
      });

    // 取得網路連線變化
    this.connectionService.getStatusObservable().subscribe((status) => {
      this.isOnline = status;
      if(this.isOnline || !this.offlineModal) {
        this.modalService.close(this.offlineAnnId);
        if(!this.cookieService.get(CookieKey.JsonWebToken) && this.router.url !== '/') {
          this.router.navigateByUrl('/');
        } 
      } else {
        this.modalService.open(this.offlineAnnId, {crossBtnIsShow: false});
      }
    });
  }

  ngAfterViewInit() {
    if(this.isOnline) {
      if(!this.cookieService.get(CookieKey.JsonWebToken) && this.router.url !== '/') {
        this.router.navigateByUrl('/');
      }
    } else {
      this.modalService.open(this.offlineAnnId, {crossBtnIsShow: false});
    }
  }

  reinitialse(): void {
    window.cubcsadoReInit;
  }

  ngOnDestroy(): void { }

  // 首頁 loading
  // deplay(0) This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
  homeInitLoading(): void {
    this.pageLoading.loadingSub.pipe(delay(0)).subscribe(loading => {
      this.loading = loading;
    });
  }

  // 預設關閉，桌機計時
  initCheckApi(): void {
    this.checkHelper.closeYHelper();
    if (!this.isMobile.value) {
      this.setTimeOut();
    }
  }

  //  聯絡我們頁面停止
  contactStopCheckApi(): void {
    if (!this.isMobile.value) {
      this.checkHelper.closeYHelper();
      clearTimeout(this.userActivity);
    }
  }

  // 互動視窗
  doCheckApi(): void {
    if (this.checkHelper.isAchieve.value) {
      this.checkHelper.openYHelper();
    } else {
      this.checkHelper.closeYHelper();
    }
  }

  // 設定時間
  setTimeOut(): void {
    this.userActivity = setTimeout(() => {
      this.doCheckApi();
    }, this.time);
  }
}
