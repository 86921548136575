import { CommonModalButtons } from '@shared/enum/common-modal.enum';
import ApiList from '@src/app/shared/enum/api-list.enum';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { StorageKey } from './../enum/storage-key.enum';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env, environment } from '@env';
import { BehaviorSubject, iif, NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, retry, tap, map } from 'rxjs/operators';
import { SHA256, enc } from 'crypto-js';
import { ApiCommonREQ } from '@shared/models/api-common-req';
import { ApiCommonRES } from '@shared/models/api-common-res';
import * as dayjs from 'dayjs';
import { RandomService } from './random.service';
import { Api000000RES } from '@shared/models/api000000-res';
import { Api000000REQ } from '@shared/models/api000000-req';
import { ResultCode } from '@shared/enum/result-code.enum';
import { Api000006RES } from '../models/api000006-res';
import { ModalService } from '@shared/_modal/modal.service';
import { UserInfoService } from './user-info.service';
import { UploadFileType } from '@shared/enum/uploadFileType.enum';
import { CheckHelperService } from '@shared/service/check-helper.service';
import { CookieService } from 'ngx-cookie-service';
import { CookieKey } from '../enum/cookie-key.enum';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private baseUrl = env.baseUrl;
  private accessToken = new BehaviorSubject('');
  private refreshToken = new BehaviorSubject('');
  public isOperationManager = new BehaviorSubject('N');
  private TOKEN_BASIC_TYPE = 'Basic';
  private TOKEN_BEARER_TYPE = 'bearer';
  private GRANT_REFRESH_TYPE = 'refresh_token';
  private GRANT_PASSWORD_TYPE = 'password';
  private GRANT_AUTH_CODE_TYPE = 'authCode';
  public loginStateObs = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private http: HttpClient,
    private storage: StorageService,
    private modalService: ModalService,
    private userInfo: UserInfoService,
    private CheckApi: CheckHelperService,
    private cookieService: CookieService
  ) {
    if (this.cookieService.get(CookieKey.JsonWebToken)) {
      this.loginStateObs.next(true);
      this.accessToken.next(this.cookieService.get(CookieKey.JsonWebToken) as string);
      this.refreshToken.next(this.cookieService.get(CookieKey.RefreshToken) as string);
      this.isOperationManager.next(this.cookieService.get(CookieKey.isOperationManager) as string);
    } else {
      this.clearLoginState();
    }
  }

  /**
   * 登入
   * 由於登入傳輸的格式與其他api不同,故另外處理
   */
  public doLogin(req: Api000000REQ): Observable<Api000000RES> {
    const fullUrl = this.baseUrl + '/oauth/token';
    // 建立 http header
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    // 登入的 Authorization header為固定值
    headers = headers.set('Authorization', `${this.TOKEN_BASIC_TYPE} ${env.accessToken}`);
    // body
    const body = new URLSearchParams();
    if (req.grant_type === this.GRANT_PASSWORD_TYPE) {
      headers = headers.set('isOperationManager', 'N');
      const encryptUsername = req.username as string;
      const encryptP = req.p as string;
      const randomKey = this.storage.get(StorageKey.randomKey) as string;

      if (randomKey) {
        headers = headers.set('RandomKey', randomKey);
      }

      body.set('grant_type', 'password');
      body.set('username', encryptUsername);
      body.set('password', encryptP);
    } else if (req.grant_type === this.GRANT_AUTH_CODE_TYPE) {
      const authCode = req.authCode as string;
      headers = headers.set('authCode', authCode);

      body.set('grant_type', 'password');
    } else if (req.grant_type === this.GRANT_REFRESH_TYPE) {
      if (!req.refresh_token || req.refresh_token === '') {
        req.refresh_token = this.refreshToken.value;
      }
      body.set('grant_type', 'refresh_token');
      body.set('refresh_token', req.refresh_token);
    } else {
      console.error('HttpService.doLogin:grant_type錯誤');
    }

    return this.http
      .post<Api000000RES>(fullUrl, body.toString(), {
        headers,
      })
      .pipe(
        catchError(error => this.handleError(error)),
        tap(res => {
          // Token
          // this.tokenType.next(res.token_type);
          // if (!environment.production) console.log('res:', res);
          if (res.access_token) {
            this.accessToken.next(res.access_token);
            if (res.refresh_token) {
              this.refreshToken.next(res.refresh_token);
            }

            this.isOperationManager.next(res.isOperationManager);
            this.setLoginState();
          } else {
            // if (!environment.production) return;
            this.clearLoginState();
            if (req.grant_type === this.GRANT_REFRESH_TYPE) {
              this.modalService.openCommon(res.ResHeader?.rtnMsg as string ?? '頁面逾時，請重新操作', '', {
                buttons: CommonModalButtons.OK,
                onOK: () => {
                  this.router.navigate(['/'], { replaceUrl: true });
                },
              });
            }
          }
        })
      );
  }

  /**
   * 登出 删除所有保存的数据
   */
  public doLogout(): void {
    // const isInner = this.userInfo.isInnerUser();
    // if (isInner) {
    //   window.location.href = `${env.baseUrl}/sso/innerLogout`;
    // } else {
    this.doPost<Api000006RES>(
      ApiList.API000006.funcId,
      ApiList.API000006.path,
      null
    ).subscribe(res => {
      this.clearLoginState();
      this.userInfo.clearRoleData();
      if (res.ResHeader.rtnCode === ResultCode.success) {
        // 移除 accessToken token & RefreshToken
        // redirect to Home page
        this.router.navigate(['/'], { replaceUrl: true });
      }
    });
    // }
    // this.clearLoginState();
    // this.userInfo.clearRoleData();
  }

  /**
   * post function
   * @param path 要打的路徑
   * @param data request data
   * @param needEncrypt 加密
   */
  public doPost<T>(
    apiId: string,
    path: string,
    data: any,
    needEncrypt: boolean = false,
    callbacks?: { onSucess?: () => void; onError?: (rtnCode: string, rtnMsg: string) => void }
  ): Observable<ApiCommonRES<T>> {
    const fullUrl = this.baseUrl + path; // 如：tokenExpiredCheck
    const req: ApiCommonREQ<any> = {
      ReqHeader: {
        txSN: `0${dayjs().format('YYMMDDHHmmss')}${new RandomService().englishAndNumbers(6)}`,
        txDate: dayjs().format('YYYYMMDDTHHmmssZZ'),
        txID: apiId,
      },
    };
    if (data && Object.keys(data).length !== 0) {
      req.body = data;
    }

    const contentType = data instanceof FormData ? '' : 'application/json';

    const opt =
      apiId === ApiList.API000004.funcId || !this.loginStateObs.value
        ? { tokenType: this.TOKEN_BASIC_TYPE, contentType }
        : { contentType };
    let headers = this.getHeader(req, opt);

    // 需加密api在header加上randomKey
    if (needEncrypt) {
      const randomKey = this.storage.get(StorageKey.randomKey) as string;

      if (randomKey) {
        headers = headers.set('RandomKey', randomKey);
      }
    }

    const response = this.http
      .post<ApiCommonRES<T>>(fullUrl, req, { headers })
      .pipe(
        mergeMap((res, index) => {
          if (
            res &&
            res.ResHeader &&
            (res.ResHeader.rtnCode === ResultCode.accessTokenIsExpired ||
              res.ResHeader.rtnCode === ResultCode.accessTokenIsInvalid)
          ) {
            const loginReq: Api000000REQ = { grant_type: 'refresh_token' };
            return this.doLogin(loginReq).pipe(
              mergeMap(() => {
                // throwError 觸發 retry
                return throwError(new Error('refresh token'));
              })
            );
          } else {
            // 開發期間console用
            this.apiLog(apiId, path, res);
            // 偵測目前打的api
            this.CheckApi.apiChecked(apiId);

            const { rtnCode, rtnMsg } = res.ResHeader;
            if (rtnCode === ResultCode.success && callbacks && callbacks.onSucess) {
              callbacks.onSucess();
            }

            if (rtnCode !== ResultCode.success) {
              if (callbacks && callbacks.onError) {
                callbacks.onError(rtnCode, rtnMsg);
              }
            }
            return of(res);
          }
        }),
        retry(1),
        catchError(error => this.handleError(error as HttpErrorResponse))
      );
    return response;
  }

  /**
   * 上傳檔案
   */
  public doUploadFile<T>(
    apiId: string,
    path: string,
    data: any,
    callbacks?: { onSucess?: () => void; onError?: (rtnCode: string, rtnMsg: string) => void }
  ): Observable<ApiCommonRES<T>> {
    const fullUrl = this.baseUrl + path;
    const jwtString = `${this.TOKEN_BEARER_TYPE} ${this.accessToken.value}`;
    const req: ApiCommonREQ<any> = {
      ReqHeader: {
        txSN: `0${dayjs().format('YYMMDDHHmmss')}${new RandomService().englishAndNumbers(6)}`,
        txDate: dayjs().format('YYYYMMDDTHHmmssZZ'),
        txID: apiId,
      },
    };

    const formData = new FormData();
    let headers = new HttpHeaders();
    // 如果登入帶 Authorization
    this.loginStateObs.subscribe(isLogin => {
      if (isLogin) {
        headers = headers.set('Authorization', jwtString);
        headers = headers.set('SignCode', '');
        headers = headers.set('isOperationManager', this.isOperationManager.value);
      } else {
        headers = headers.set('SignCode', '');
      }
    });

    let reqStr = '';
    let blob = new Blob();
    const setRequest = (info: any): void => {
      req.body = info;
      reqStr = JSON.stringify(req);
      headers = headers.set('SignCode', this.sign(reqStr));
      blob = new Blob([reqStr], {
        type: 'application/json',
      });
    };

    const { dtoInfo, dto, ...other } = data;
    if (dtoInfo) {
      if (other) {
        for (const [key] of Object.entries(other)) {
          if (key === UploadFileType.PROPOSALFILE) {
            formData.append(key, data.proposalFile);
          } else if (key === UploadFileType.PROPOSALFILENAME) {
            formData.append(key, data.proposalFileName);
          } else if (key === UploadFileType.PROPOSALFILEDESC) {
            formData.append(key, data.proposalFileDesc);
          } else if (key === UploadFileType.LOGOFILENAME) {
            formData.append(key, data.logoFileName);
          } else if (key === UploadFileType.LOGOFILE) {
            formData.append(key, data.logoFile);
          } else if (key === UploadFileType.GOODSFILENAME1) {
            formData.append(key, data.goodsFileName1);
          } else if (key === UploadFileType.GOODSFILE1) {
            formData.append(key, data.goodsFile1);
          } else if (key === UploadFileType.GOODSFILENAME2) {
            formData.append(key, data.goodsFileName2);
          } else if (key === UploadFileType.GOODSFILE2) {
            formData.append(key, data.goodsFile2);
          } else if (key === UploadFileType.GOODSFILENAME3) {
            formData.append(key, data.goodsFileName3);
          } else if (key === UploadFileType.GOODSFILE3) {
            formData.append(key, data.goodsFile3);
          } else if (key === UploadFileType.BRANCHFILENAME) {
            formData.append(key, data.branchFileName);
          } else if (key === UploadFileType.BRANCHFILE) {
            formData.append(key, data.branchFile);
          } else if (key === UploadFileType.EXPOSUREFILENAME) {
            formData.append(key, data.exposureFileName);
          } else if (key === UploadFileType.EXPOSUREFILE) {
            formData.append(key, data.exposureFile);
          }
        }
      }

      if (dto.type === UploadFileType.DOCUMENT) {
        formData.append(dto.type, data.document);
      } else if (dto.type === UploadFileType.FILE) {
        formData.append(dto.type, data.file);
      } else if (dto.type === UploadFileType.ATTACHMENT) {
        formData.append(dto.type, data.attachment);
      } else if (dto.type === UploadFileType.FORMFILE) {
        formData.set(dto.type, data.formFile, data.outputFileName);
      } else if (dto.type === UploadFileType.MULTIFILE && other.contractFileList) {
        other.contractFileList.forEach((item: any) => {
          formData.append(dto.type, item.file);
        });
      } else if (dto.type === UploadFileType.ANNFILES) {
        other.fileList.forEach((item: any) => {
          if(item.file) {
             formData.append(dto.type, item.file);
          }
        });
      } else if (dto.type === UploadFileType.BACKGROUNDIMGFILE && data.imgFile) {
        formData.set(dto.type, data.imgFile)
      }
      setRequest(dtoInfo);
      formData.append(dto.name, blob);
    }

    const response = this.http
      .post<ApiCommonRES<T>>(fullUrl, formData, { headers })
      .pipe(
        mergeMap((res, index) => {
          if (
            res &&
            res.ResHeader &&
            (res.ResHeader.rtnCode === ResultCode.accessTokenIsExpired ||
              res.ResHeader.rtnCode === ResultCode.accessTokenIsInvalid)
          ) {
            const loginReq: Api000000REQ = { grant_type: 'refresh_token' };
            return this.doLogin(loginReq).pipe(
              mergeMap(() => {
                // throwError 觸發 retry
                return throwError(new Error('refresh token'));
              })
            );
          } else {
            // 開發期間console用
            this.apiLog(apiId, path, res);
            const { rtnCode, rtnMsg } = res.ResHeader;
            if (rtnCode === ResultCode.success && callbacks && callbacks.onSucess) {
              callbacks.onSucess();
            }

            if (rtnCode !== ResultCode.success) {
              if (callbacks && callbacks.onError) {
                callbacks.onError(rtnCode, rtnMsg);
              }
            }
            return of(res);
          }
        }),
        retry(1),
        catchError(error => this.handleError(error as HttpErrorResponse))
      );
    return response;
  }

  /**
   * 取得送出http header
   */
  private getHeader(body: any, opt: { tokenType?: string; contentType: string }): HttpHeaders {
    opt.tokenType = opt.tokenType ?? this.TOKEN_BEARER_TYPE;
    let headers = new HttpHeaders();
    if (opt.contentType !== '') {
      headers = headers.set('Content-Type', `${opt.contentType}; charset=utf-8`);
    }
    if (opt.tokenType === this.TOKEN_BEARER_TYPE) {
      // 將 this.tokenType.value 改成 this.TOKEN_BEARER_TYPE
      const jwtString = `${this.TOKEN_BEARER_TYPE} ${this.accessToken.value}`;
      headers = headers.set('Authorization', jwtString);
    }
    headers = headers.set('SignCode', this.sign(JSON.stringify(body)));
    headers = headers.set('isOperationManager', this.isOperationManager.value);
    headers.set('SameSite', 'None');
    headers.set('X-Frame-Options', 'SAMEORIGIN');

    return headers;
  }

  /**
   * 簽章
   * @param body 要傳輸的body
   */
  public sign(body: string): string {
    // 使用 SHA-256 密碼雜湊函數演算法，產生SignCode欄位步驟如下說明：
    // Step 1. 由以下欄位組成SignCode String:
    // 1.signBlock(固定值): "DzANBgNVBAgTBnRhaXdhbjEPMA0GA1UEBxMGdGFpcGVpMRMwEQYDVQQKEwp0aGlu"
    // 2.Http Body內容。
    // (需要時SignCode可用Server憑證加密)
    // Step 2. SHA-256雜湊
    // Step 3. 轉成Hex，即16進位小寫字串(長度為64)
    body = env.signBlock + body;
    const signCode = SHA256(body.replace(/[\s\r\n]/g, ''));
    const signCodeStr = signCode.toString(enc.Hex);
    return signCodeStr;
  }

  /**
   * error handle
   * @param error error
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('發生客戶端或網絡錯誤');
      console.error('An error occurred:\r\n', error.error.message);
    } 
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error('中台回傳 http error');
      // console.error(`Backend returned code ${error.status}\r\n` + `body was:\r\n`, error);
    }
    // return an observable with a user-facing error message
    // return throwError('Something bad happened; please try again later.');
    return NEVER;  // KL:20220615 - honestly, you have already taken care of the error, so why are you logging to the browser-console?
  }

  private DONTLOGME = [
    '/api/checkFuncRole',
    '/api/checkIsInnerAccount',
    '/api/getCaptcha',
    '/api/getPublicKey',
    '/api/queryAnnouncement',
    '/api/queryBUPackageList',
    '/api/queryCooperationCasesList',
    '/api/queryFuncRoleList',
    '/api/queryIndustryList',
    '/api/queryIndustryTagList',
    '/api/queryLoginUserInfo',
    '/api/queryOrgReqApiProductList',
    '/api/queryOrgReqPackageListByGroupId',
    '/api/queryOrgUserInfoList',
    '/api/queryRecommandPackageList',
    '/api/queryUserInfo',
    '/api/topList',
  ]
  /**
   *  呼叫api時將回傳資料輸出至console
   */
  private apiLog(apiId: string, url: string, data: any): void {
    if (environment.production || this.DONTLOGME.includes(url)) {
      return;
    }

    const { ResHeader: { rtnCode }, } = data;
    // console.log('\r');
    console.groupCollapsed(
      `%c 📍***${url} header.code[${rtnCode}]*** `,
      'background-color: #F7CD46; color: #221903; font-size: 14px; font-weight: bold;'
    );
    console.log(
      `%c >>> Request(${url}) (${apiId})⤵ `,
      'background-color: #7CBC9D; color: white; font-size: 14px; font-weight: bold;'
    );
    console.log(data.body ? data.body : {});
    console.groupEnd();
  }

  /**
   * 設定登入狀態
   */
  private setLoginState(): void {
    const time = 2 * 60 * 60 * 1000;
    const refreshTime = 24 * 60 * 60 * 1000;
    const expiresTime = new Date(new Date().getTime() + time);
    const expiresRefreshTime = new Date(new Date().getTime() + refreshTime);
    this.cookieService.set(CookieKey.isOperationManager, this.isOperationManager.value, { expires: expiresTime });
    this.cookieService.set(CookieKey.JsonWebToken, this.accessToken.value, { expires: expiresTime });
    this.cookieService.set(CookieKey.RefreshToken, this.refreshToken.value, { expires: expiresRefreshTime });
    // this.storage.set(StorageKey.JsonWebToken, this.accessToken.value);
    // this.storage.set(StorageKey.RefreshToken, this.refreshToken.value);
    this.loginStateObs.next(true);
  }

  /**
   * 清除登入狀態
   */
  public clearLoginState(): void {
    this.isOperationManager.next('N');
    this.accessToken.next('');
    this.refreshToken.next('');
    // this.cookieService.delete(CookieKey.isOperationManager);
    // this.cookieService.delete(CookieKey.JsonWebToken);
    // this.cookieService.delete(CookieKey.RefreshToken);
    this.clearCookie(CookieKey.isOperationManager);
    this.clearCookie(CookieKey.JsonWebToken);
    this.clearCookie(CookieKey.RefreshToken);
    this.loginStateObs.next(false);
    console.log('登出')
  }

  // 清除Cookie
  clearCookie(name: string) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  /**
   * 訂閱登入狀態
   */
  public subLoginState(): Observable<boolean> {
    return this.loginStateObs.asObservable();
  }

  download<T>(apiId: string, path: string, data: any): Observable<any> {
    const fullUrl = this.baseUrl + path; // 如：tokenExpiredCheck
    const req: ApiCommonREQ<any> = {
      ReqHeader: {
        txSN: `0${dayjs().format('YYMMDDHHmmss')}${new RandomService().englishAndNumbers(6)}`,
        txDate: dayjs().format('YYYYMMDDTHHmmssZZ'),
        txID: apiId,
      },
    };
    if (data && Object.keys(data).length !== 0) {
      req.body = data;
    }

    const contentType = 'application/json';
    const opt = this.loginStateObs.value
      ? { contentType }
      : { tokenType: this.TOKEN_BASIC_TYPE, contentType };
    const headers = this.getHeader(req, opt);

    const response = this.http
      .post(fullUrl, req, {
        headers,
        responseType: 'arraybuffer',
        observe: 'response', // 取得 Response Header 值
      })
      .pipe(res => {
        return res;
      });
    catchError(error => this.handleError(error as HttpErrorResponse));

    return response;
  }

  getRepair(): Observable<any> {
    return this.http.get('/apiv2/config/repair');
  }

  /**
   * call external get tax number info
   * @param url 
   */
  doGetExternal(businessNo: string): Observable<any> {
    return this.http.get(`/apiv2/externalApi/getTaxNum`, {
      params: { businessNo }
    });
  }
}
