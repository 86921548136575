export enum StorageKey {
  // 以下 =正式= 使用
  /**
   * 登入Token
   */
  JsonWebToken = 'JsonWebToken',
  /**
   * 登入者Email
   */
  account = 'account',
  // 以下 =unit test= 使用
  /**
   * Storage service unit test 使用
   */
  Unittest_Storage = 'Unittest_Storage',

  /**
   * RSA 加密用 PublicKey
   */
  RSAPublicKey = 'RSAPublicKey',

  /**
   * 中台用於判斷動態public key使用的隨機碼
   */
  randomKey = 'randomKey',
  /**
   * 更新Token
   */
  RefreshToken = 'RefreshToken',

  /**
   * isOperationManager
   */
  isOperationManager = 'isOperationManager',

  rememberAccount = 'rememberAccount',

  isRemember = 'isRemember',

  /**
   * SSO BACK redirect url
   */
  ssoBackRedirectUrl = 'ssoBackRedirectUrl',

  /**
   * ticket-list - store user-sort/filter in session-storage
   */
  ticketListFilterData = 'ticketListFilterData',

  /**
   * ann-list - store user-currentPage/annType in session-storage
   */
  annListStoreData = 'annListStoreData',
  opAnnListStoreData = 'opAnnListStoreData',
  frontAnnStoreData = 'frontAnnStoreData'
}
