import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // 啟用資料繫結功能

import { PageLoadingInterceptor } from '@shared/service/page-loading.interceptor';
import { SharedModule } from '@app/shared/shared.module';
import { SideMenuHackInterceptor } from './private-page/cathay-operation-page/workflow2/workflow2.interceptors';

// import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
// import { NzSelectModule } from 'ng-zorro-antd/select';

export let AppInjector: Injector;

@NgModule({
  declarations: [AppComponent],
  // 這個模組所需用到的Angular提供的或第三方提供的Angular資源庫
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    GoogleTagManagerModule.forRoot({ id: 'GTM-T683WHP' }),
    // NzSelectModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: PageLoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SideMenuHackInterceptor, multi: true },
    // { provide: 'googleTagManagerId', useValue: 'GTM-T683WHP' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { 
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
