export const environment = {
  baseUrl: 'https://lena.cfh-das-ct-lab.net',
  environment: 'ut',
  production: false,

  tokenType: 'Basic',
  /**
   * 未登入前Http Header Authorization欄位值
   */
  accessToken: 'Y2xpZW50SWQ6c2VjcmV0',
  /**
   * 使用者名稱,密碼加密用 測試階段使用，後續改為跟中台取得
   */
  rsaPublicKey:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmWI2jtKwvf0W1hdMdajch+mFx9FZe3CZnKNvT/d0+2O6V1Pgkz7L2FcQx2uoV7gHgk5mmb2MZUsy/rDKj0dMfLzyXqBcCRxD6avALwu8AAiGRxe2dl8HqIHyo7P4R1nUaea1WCZB/i7AxZNAQtcCcSvMvF2t33p3vYXY6SqMucMD4yHOTXexoWhzwRqjyyC8I8uCYJ+xIfQvaK9Q1RzKRj99IRa1qyNgdeHjkwW9v2Fd4O/Ln1Tzfnk/dMLqxaNsXPw37nw+OUhycFDPPQF/H4Q4+UDJ3ATf5Z2yQKkUQlD45OO2mIXjkWprAmOCi76dLB2yzhCX/plGJwcgb8XHEQIDAQAB',
  /**
   * signBlock(固定值)
   * http header signCode 欄位使用
   */
  signBlock: '1bdef593c075382d1ba43db0fdfb99791f347b27964b969cc22cd39533abace3',
  googleGA: '',
};
