import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InitialLoadingService {
  isHomeInitial = true;
  isCookieInitial = true;
  constructor() {}
}
