export enum CookieKey {
  /**
   * 登入Token
   */
  JsonWebToken = 'JsonWebToken',
  /**
   * 更新Token
   */
  RefreshToken = 'RefreshToken',
  /**
   * isOperationManager
   */
  isOperationManager = 'isOperationManager',
  /**
   * 前台公告
   */
  isFrontAnn= 'isFrontAnn',
}
