import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  private defaultTitleSuffix = 'CaaS 國泰生態圈服務平台';
  private defaultTitle = 'CaaS 國泰生態圈服務平台｜找合作 / 找趨勢 / 找機會';
  private currentTitle = '';
  private currentAppVersion = '0.0.1';

  title$ = new BehaviorSubject(this.defaultTitle);
  appVersion$ = new BehaviorSubject(this.currentAppVersion);

  constructor(private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private http: HttpClient) {
    this.http.get('/apiv2/status').subscribe((val: any) => {
      this.currentAppVersion = val.build;
      this.appVersion$.next(val.build);
    })

    // https://stackoverflow.com/a/46697826/16552410
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.route.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          }
          else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          else {
            return null;
          }
        }
        return null;
      })
    ).subscribe(val => {
      if (!!val) {
        this.setTitle(val);
      }
    })
  }

  setTitle(title?: any) {
    if (!!title) {
      this.currentTitle = `${title} | ${this.defaultTitleSuffix}`;
    }
    else {
      this.currentTitle = this.defaultTitle;
    }
    this._setTitle();
    this.title$.next(this.currentTitle);
  }

  private _setTitle() {
    this.title.setTitle(this.currentTitle);

    let exist = this.meta.getTag('name=title');
    if (!!exist) {
      exist.remove();
    }
    this.meta.addTag({ name: 'title', content: this.currentTitle });

    // if (!environment.production) {
    //   console.log('set title:', this.currentTitle);
    // }
  }
}
