import { Injectable } from '@angular/core';
import { CommonModalButtons } from '@src/app/shared/enum/common-modal.enum';
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: any[] = [];

  constructor() {}

  add(modal: any): void {
    this.modals.push(modal);
  }

  remove(id: string): void {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(
    id: string,
    options?: {
      crossBtnIsShow?: boolean;
    }
  ): void {
    const modal = this.modals.find(x => x.id === id);
    modal.open(options?.crossBtnIsShow);
  }
  // 共用Modal
  /*
  範例
  this.modalService.openCommon("標題","測試",
    {
      buttons:CommonModalButtons.OKandCancel,
      okText:"OKOK",
      cancelText:"Cancel",
      onOK:()=>{alert("ok");},
      onCancel:()=>{ alert("cancel");},
    });
  */
  openCommon(
    title: string,
    content: string,
    options?: {
      buttons?: CommonModalButtons;
      okText?: string;
      cancelText?: string;
      crossBtnIsShow?: boolean;
      btnClass?: any;
      onOK?: () => void;
      onCancel?: () => void;
    }
  ): void {
    const modal = this.modals.find(x => x.id === 'common-modal');
    if (modal) {
      modal.open(
        title,
        content,
        options?.buttons,
        options?.okText,
        options?.cancelText,
        options?.crossBtnIsShow,
        options?.btnClass,
        options?.onOK,
        options?.onCancel
      );
    }
  }

  closeCommon(): void {
    const modal = this.modals.find(x => x.id === 'common-modal');
    if (modal > -1) {
      modal.ok();
    }
  }

  close(id: string): void {
    const modal = this.modals.find(x => x.id === id);
    if (modal) {
      modal.close();
    }
  }
}
