import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ModalService } from './modal.service';
import { CommonModalButtons } from '@src/app/shared/enum/common-modal.enum';
@Component({
  selector: 'common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommonModalComponent implements OnInit, OnDestroy {
  public id: string;
  public title?: string;
  public content?: string;
  public okText: string = '確定';
  public cancelText: string = '取消';
  public crossBtnIsShow = true;
  public buttons: CommonModalButtons = CommonModalButtons.OK;
  public btnClass: any;
  public onOK?: () => void;
  public onCancel?: () => void;
  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
    this.id = this.element.id;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);

    // this.element.addEventListener('click', (el: any) => {
    //   if (el.target.className === 'modal') {
    //     this.cancel();
    //   }
    // });

    this.modalService.add(this);
  }

  public get buttonsSets(): typeof CommonModalButtons {
    return CommonModalButtons;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open
  open(
    title: string,
    content: string,
    buttons?: CommonModalButtons,
    okText?: string,
    cancelText?: string,
    crossBtnIsShow?: boolean,
    btnClass?: any,
    onOK?: () => void,
    onCancel?: () => void
  ): void {
    this.element.style.display = 'block';
    this.title = title;
    this.content = content;
    this.buttons = buttons ?? this.buttons;
    this.okText = okText ?? this.okText;
    this.cancelText = cancelText ?? this.cancelText;
    this.crossBtnIsShow = crossBtnIsShow ?? true;
    this.btnClass = btnClass ?? null;
    this.onOK = onOK;
    this.onCancel = onCancel;
    document.body.classList.add('modal-open');
  }
  // close
  ok(): void {
    if (this.onOK) {
      this.onOK();
    }
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
  }
  cancel(): void {
    if (this.onCancel) {
      this.onCancel();
    }
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
  }
  cross(): void {
    if (this.buttons === CommonModalButtons.OK) {
      this.ok();
    } else if (this.buttons === CommonModalButtons.OKandCancel) {
      this.cancel();
    }
  }
}
