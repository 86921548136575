import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { EnvMode } from "@src/app/shared/enum/env-mode.enum";

if (environment.production) {
  enableProdMode();
  window.console.log = () => {};
}
// Celebrus
// const scriptEl = window.document.createElement('script');
// scriptEl.type = 'text/javascript';
// scriptEl.async = true;
// if (environment.environment === EnvMode.UT) {
//   scriptEl.src = '/assets/script/CelebrusInsert/ut/CelebrusInsert.js';
// } else if (environment.environment === EnvMode.SIT) {
//   scriptEl.src = '/assets/script/CelebrusInsert/ut/CelebrusInsert.js';
// } else if (environment.environment === EnvMode.UAT) {
//   scriptEl.src = '/assets/script/CelebrusInsert/uat/CelebrusInsert.js';
// } else if (environment.environment === EnvMode.PROD) {
//   scriptEl.src = '/assets/script/CelebrusInsert/prod/CelebrusInsert.js';
// }
// window.document.body.appendChild(scriptEl);

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
};


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
