import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-page-loading',
  templateUrl: './page-loading.component.html',
  styleUrls: ['./page-loading.component.scss'],
})
export class PageLoadingComponent implements OnInit {
  @Input() areaLoading = false;
  constructor() {}
  options: AnimationOptions = {
    path: '/assets/loading/PageLoading.json',
  };
  ngOnInit(): void {}
}
