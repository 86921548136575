/**
 * 布林值
 */
export enum ResStatus {
  /**
   * true
   */
  true = 'Y',
  /**
   * false
   */
  false = 'N',
  /**
   * old
   */
  old = 'O',
}
