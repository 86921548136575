import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { StorageKey } from '../enum/storage-key.enum';
import { CookieService } from 'ngx-cookie-service';
import { CookieKey } from '../enum/cookie-key.enum';
@Injectable({
  providedIn: 'root',
})
export class CheckHelperService {
  // 檢查 API 是否有呼叫
  isAchieve = new BehaviorSubject(false);
  // 申請商品或追蹤商品
  isLoginApi = ['APIA30104', 'APIA30103'];
  // 執行註冊
  notLoginApi = ['APIA10103'];

  // 控制開合
  helperActive = new BehaviorSubject<boolean>(false);

  constructor(private storage: StorageService, private cookieService: CookieService) {}

  /**
   * 未登入：3分鐘內未執行註冊即展開詢問視窗
   * 已登入：3分鐘內未申請商品或追蹤商品即展開詢問視窗
   * @param apiId API funcId
   */
  apiChecked(apiId: string): void {
    if (this.cookieService.get(CookieKey.JsonWebToken)) {
      if (this.isLoginApi.indexOf(apiId) !== -1) {
        this.isAchieve.next(false);
      } else {
        this.isAchieve.next(true);
      }
    } else {
      if (this.notLoginApi.indexOf(apiId) !== -1) {
        this.isAchieve.next(false);
      } else {
        this.isAchieve.next(true);
      }
    }
  }

  // 控制開合
  openYHelper(): void {
    this.helperActive.next(true);
  }
  closeYHelper(): void {
    this.helperActive.next(false);
  }
}
