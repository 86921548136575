/**
 * call api result code
 */
export enum ResultCode {
  success = '000001',
  /**
   * 輸入參數有誤，請檢查是否為合法參數
   */
  inputParameterError = '000002',
  /**
   * 解析json Data失敗，請檢查是否為合法格式
   */
  jsonDeserializeError = '000003',
  /**
   * AccessToken is not found
   */
  accessTokenIsNotFound = '000004',
  /**
   * AccessToken is invalid
   */
  accessTokenIsInvalid = '000005',
  /**
   * AccessToken is expired
   */
  accessTokenIsExpired = '000006',
  /**
   * 戶尚未綁定
   */
  userNotBinding = '000008',
  /**
   * 使用者尚未註冊
   */
  unRegistered = '000029',
  /**
   * 使用者密碼輸入有誤
   */
  pwdInvalid = '000030',
  /**
   * 密碼不符合規則，請確認。
   */
  pwdNotComplyRules='020117',
  /**
   * 新密碼不可與用戶代號相同。
   */
  newPwdCannotSameEmail='020118',
  /**
   * 系統錯誤
   */
  systemError = '000099',
  /**
   * 信件連結已失效
   */
  emaiLinkFail = 'A10103',
  /**
   * 不同意條款
   */
  disagreeRules = 'A10104',
  /**
   * 邀請註冊失敗
   */
  invitedRegisterFail = 'A10108',
  /**
   * 不同意註冊。
   */
  disagreeRegister = 'A10106',
  /**
   * 非外部註冊
   */
  notOutterRegister = 'A10107',

  /**
   * 非外部註冊
   */
  notInnerRegister = 'A10109',
  /**
   * 驗證失敗，非國泰內部Email
   */
  tokenFailNotInnerEmail = 'A10110',
  /**
   * 此帳號已存在
   */
  accountRepeated = 'A10111',
  /**
   * 驗證碼驗證錯誤
   */
  captchaError = 'A10112',
  /**
   * 註冊失敗
   */
  registerFail = 'A10113',
  /**
   * 邀請綁定失敗
   */
  invitedBindingFail = 'A10114',
  /**
   * 申請商品失敗
   */
  applyPackageFail = 'A30101',
  /**
   * 發送郵件失敗
   */
  sendEmailFail = '000024',
  /**
   * 政府API有資料 為"非營業中狀態"公司
   * 企業登記狀態為廢止/解散/撤銷，請再次確認
   */
  taxIdNotOpen = '070208',
  /**
   * 政府API無資料，DB無資料
   *  無法找到符合條件的查詢結果
   */
  taxIdAllNoData = '070209',
  /**
   * 工商登記API查詢無資料，DB有資料，需判斷是否為待審核公司，只回傳代碼/訊息。
   */
  /**
   * a.待審核公司
   * 企業綁定審核中，請至「外部帳號管理」審核該公司企業帳號。
   */
   taxIdPendingReview = '070210',
    /**
   * 
   * b.無資料(非待審，已存在統編)
   * 企業統一編號已存在，請勿重複新增。
   */
  taxIdNoRepeatedlyAdd = '070211',
}
