import { AlphaNumCheckDirective } from './validators/alpha-num-check.directive';
import { DateFormatPipe, DateTimeFormatPipe, TwelveClockFormatPipe, MonthFormatPipe, DateTimeSecondDashFormatPipe, DateTimeSecondFormatPipe } from './pipes/date-time-format.pipe';
import { ModalModule } from './_modal/modal.module';
import { PublicModalModule } from '../public-page/common/modal';
// import { AppRoutingModule } from '../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// cv Validator
import { CustIdCheckDirective } from './validators/cust-id-check.directive';
import { RequiredDirective } from './validators/required.directive';
import { PhoneCheckDirective } from './validators/phone-check.directive';
import { TaxIdCheckDirective } from './validators/tax-id-check.directive';
import { MaxLengthCheckDirective } from './validators/max-length-check.directive';
import { MaxLengthDirective } from './validators/max-length.directive';
import { MinLengthCheckDirective } from './validators/min-length-check.directive';
import { HalfWidthCheckDirective } from './validators/half-width-check.directive';
import { EmailCheckDirective } from './validators/email-check.directive';
import { ChineseCheckDirective } from './validators/chinese-check.directive';
import { PNumCheckDirective } from './validators/password-check.directive';

import { SwaggerComponent } from './swagger/swagger.component';
import { ReqStatusConversionPipe } from './pipes/req-status-conversion.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { QueryStatusNameConversionPipe } from './pipes/query-status-name-conversion.pipe';
import { ReadStatusConversionPipe } from './pipes/read-status-conversion.pipe';
import { NoticeTypePipe } from './pipes/notice-type.pipe';
import { KSeparatorNumberOnlyDirective, NumberOnlyDirective } from './validators/number-only.directive';
// 共用組件
import { TooltipComponent } from './layout/components/tooltip/tooltip.component';
import { WrapperLayoutComponent } from '@shared/layout/wrapper-layout/wrapper-layout.component';
import { CommonHeaderComponent } from '@src/app/shared/layout/components/common-header/common-header.component';
import { BreadcrumbsComponent } from '@shared/layout/components/breadcrumbs/breadcrumbs.component';
import { FooterComponent } from '@shared/layout/components/footer/footer.component';
import { ToTopComponent } from '@shared/layout/components/to-top/to-top.component';
import { FrontWrapperLayoutComponent } from './layout/front-wrapper-layout/front-wrapper-layout.component';
import { FixedHelperComponent } from './layout/components/fixed-helper/fixed-helper.component';
import { ApplyStatusConversionPipe } from './pipes/apply-status-conversion.pipe';
// import { TwelveClockFormatPipe } from './pipes/twelve-clock-format.pipe';
import { PageLoadingComponent } from './layout/components/page-loading/page-loading.component';
// import { InitialLoadingComponent } from './layout/components/initial-loading/initial-loading.component';
// third part
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_svg';
import { IconCircleLoadingComponent } from './layout/components/icon-circle-loading/icon-circle-loading.component';

import { AreaCodeCheckDirective } from './validators/area-code-check.directive';
import { SpecialCharCheckDirective } from './validators/special-char-check.directive';
import { AuditStatusConversionPipe } from './pipes/audit-status-conversion.pipe';
import { DateFormatCheckDirective } from './validators/date-format-check.directive';
import { PhoneNumberCheckDirective } from './validators/phone-number-check.directive';
// import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ProductTypePipe } from './pipes/product-type.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RouterModule } from '@angular/router';
// import { MonthFormatPipe } from './pipes/month-format.pipe';
import { ExcelComponent } from './excel/excel.component';
import { IconNonfinishAuditComponent } from './layout/components/icon-nonfinish-audit/icon-nonfinish-audit.component';
import { uatStatusConversionPipe } from './pipes/ut-status-conversion.pipe';
import { ContractStatusConversionPipe } from './pipes/contract-status-conversion.pipe';
import { TagTypeStatusConversionPipe } from './pipes/tag-type-status-conversion.pipe';
import { FileCharacterCheckDirective } from './validators/file-character-check.directive';
import { TooltipButtonComponent } from './layout/components/tooltip-button/tooltip-button.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalService } from './_modal';
import { StyleGuideComponent } from './layout/components/style-guide/style-guide.component';
import { AssetCollectionComponent } from './layout/components/style-guide/asset-collection/asset-collection.component';
import { SideLayoutHeaderFooterComponent } from './layout/side-layout-header-footer/side-layout-header-footer.component';
import { SideLayoutComponent } from './layout/side-layout/side-layout.component';
import { FieldLabelComponent } from './components/field-label/field-label.component';
import { CommonHeaderSecondComponent } from './layout/components/common-header-second/common-header-second.component';
import { ProgressComponent } from '../private-page/api-manage/apply-list/progress/progress.component';
import { ChineseCheckAndLengthCheckDirective } from './validators/chinese-check-and-length-check.directive';
import { TextLengthCheckDirective } from './validators/text-length-check.directive';
import { UploadFileMultipleComponent } from './components/upload-file-multiple/upload-file-multiple.component';
import { EditorComponent } from './components/editor/editor.component';
import { FileViewComponent } from './components/file-view/file-view.component';
import { NewPNumCheckDirective } from './validators/new-password-check.directive';
import { SafeHtmlPipe } from '../public-page/modules/home/safe-html.pipe';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { MinNumberDirective } from './validators/min-number.directive';
import { ToFullWidthPipe } from './pipes/text-width-conversion.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    CustIdCheckDirective,
    RequiredDirective,
    PhoneCheckDirective,
    TaxIdCheckDirective,
    MaxLengthCheckDirective,
    MaxLengthDirective,
    MinLengthCheckDirective,
    HalfWidthCheckDirective,
    PNumCheckDirective,
    NewPNumCheckDirective,
    EmailCheckDirective,
    DateFormatPipe,
    DateTimeFormatPipe,
    DateTimeSecondDashFormatPipe,
    TwelveClockFormatPipe,
    EmailCheckDirective,
    SwaggerComponent,
    ReqStatusConversionPipe,
    PaginationComponent,
    QueryStatusNameConversionPipe,
    ReadStatusConversionPipe,
    NoticeTypePipe,
    NumberOnlyDirective,
    TooltipComponent,
    WrapperLayoutComponent,
    CommonHeaderComponent,
    BreadcrumbsComponent,
    FooterComponent,
    ToTopComponent,
    FrontWrapperLayoutComponent,
    FixedHelperComponent,
    ApplyStatusConversionPipe,
    ChineseCheckDirective,
    ChineseCheckAndLengthCheckDirective,
    PageLoadingComponent,
    // InitialLoadingComponent,
    AlphaNumCheckDirective,
    IconCircleLoadingComponent,
    AreaCodeCheckDirective,
    SpecialCharCheckDirective,
    AuditStatusConversionPipe,
    DateFormatCheckDirective,
    PhoneNumberCheckDirective,
    FileCharacterCheckDirective,
    TextLengthCheckDirective,
    SafeHtmlPipe,
    ProductTypePipe,
    ExcelComponent,
    MonthFormatPipe,
    IconNonfinishAuditComponent,
    uatStatusConversionPipe,
    ContractStatusConversionPipe,
    TagTypeStatusConversionPipe,
    TooltipButtonComponent,
    StyleGuideComponent,
    AssetCollectionComponent,
    SideLayoutHeaderFooterComponent,
    SideLayoutComponent,
    FieldLabelComponent,
    CommonHeaderSecondComponent,
    ProgressComponent,
    UploadFileMultipleComponent,
    EditorComponent,
    FileViewComponent,
    DateTimeSecondFormatPipe,
    MinNumberDirective,
    ToFullWidthPipe,
    KSeparatorNumberOnlyDirective
  ],
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    ModalModule,
    PublicModalModule,
    YoutubePlayerComponent,
    // AppRoutingModule,
    RouterModule,
    PdfViewerModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'zh',
    }),
  ],
  exports: [
    CustIdCheckDirective,
    RequiredDirective,
    ChineseCheckDirective,
    ChineseCheckAndLengthCheckDirective,
    PhoneCheckDirective,
    PhoneNumberCheckDirective,
    TaxIdCheckDirective,
    MaxLengthCheckDirective,
    MaxLengthDirective,
    MinLengthCheckDirective,
    HalfWidthCheckDirective,
    PNumCheckDirective,
    NewPNumCheckDirective,
    EmailCheckDirective,
    DateFormatPipe,
    DateTimeFormatPipe,
    DateTimeSecondDashFormatPipe,
    TwelveClockFormatPipe,
    ReqStatusConversionPipe,
    EmailCheckDirective,
    SwaggerComponent,
    PaginationComponent,
    QueryStatusNameConversionPipe,
    ReadStatusConversionPipe,
    NoticeTypePipe,
    NumberOnlyDirective,
    TooltipComponent,
    FrontWrapperLayoutComponent,
    WrapperLayoutComponent,
    CommonHeaderComponent,
    BreadcrumbsComponent,
    FooterComponent,
    ToTopComponent,
    ApplyStatusConversionPipe,
    PageLoadingComponent,
    // InitialLoadingComponent,
    AlphaNumCheckDirective,
    IconCircleLoadingComponent,
    AreaCodeCheckDirective,
    SpecialCharCheckDirective,
    AuditStatusConversionPipe,
    DateFormatCheckDirective,
    FileCharacterCheckDirective,
    TextLengthCheckDirective,
    SafeHtmlPipe,
    ProductTypePipe,
    ExcelComponent,
    IconNonfinishAuditComponent,
    uatStatusConversionPipe,
    ContractStatusConversionPipe,
    TagTypeStatusConversionPipe,
    TooltipButtonComponent,
    LottieModule,
    TranslateModule,
    ModalModule,
    PublicModalModule,
    SideLayoutHeaderFooterComponent,
    SideLayoutComponent,
    FieldLabelComponent,
    CommonHeaderSecondComponent,
    ProgressComponent,
    UploadFileMultipleComponent,
    EditorComponent,
    FixedHelperComponent,
    FileViewComponent,
    DateTimeSecondFormatPipe,
    YoutubePlayerComponent,
    MinNumberDirective,
    KSeparatorNumberOnlyDirective
  ],
  providers: [
    DateFormatPipe,
    DateTimeFormatPipe,
    DateTimeSecondDashFormatPipe
  ],
})
export class SharedModule {}
